const routerRegex = {
  main: {
    path: '/',
  },
  login: {
    path: '/login',
  },
  user: {
    path: '/user',
    list: {
      path: '/user/list',
    },
    players: {
      path: '/user/players',
    },
    pro: {
      path: '/user/pro',
    },
    detail: {
      path: '/user/list/:userId',
      sbtList: {
        path: 'sbt',
      },
    },
  },
  nft: {
    path: '/nft',
    sbt: {
      path: '/nft/sbt',
      sub: {
        path: '/nft/sbt/:sbtId/sub',
      },
      request: {
        path: '/nft/sbt/:sbtId/request',
      },
    },
    award: {
      path: '/nft/award',
    },
    competition: {
      path: '/nft/comp',
    },
  },
  banner: {
    path: '/banner',
  },
}

export default routerRegex
