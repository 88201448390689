import { Switch, TableCell, TableRow } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { getSubbadges, updateSubBadgeStatus } from '../../../../api/sbt'
import Table from '../../../../components/template/Container/Table/Table'
import { useToastContext } from '../../../../context/ToastContext'
import { tabConfig } from '../tabConfig'
import { headers, sampleData } from './config'

export default function Sub() {
  const [tabs] = useState(tabConfig)
  const [subBadges, setSubBadges] = useState(sampleData)
  const navigate = useNavigate()
  const { sbtId } = useParams()
  const { addToast } = useToastContext()

  const onClickTab = value => {
    navigate(`/nft/sbt/${sbtId}/${value}`)
  }

  const onClickToggle = async (checked, id) => {
    try {
      const updated = subBadges.map(item =>
        item._id === id ? { ...item, isShow: checked } : { ...item }
      )
      await updateSubBadgeStatus(id, checked)
      setSubBadges(updated)
    } catch (e) {
      addToast({
        message: `${e.response.data.error || e.response.data.message || e}`,
        severity: 'error',
      })
    }
  }

  useEffect(() => {
    try {
      async function getSub() {
        const { data } = await getSubbadges(sbtId)
        setSubBadges(data)
      }
      getSub()
    } catch (e) {
      console.error(e)
    }
  }, [sbtId])

  const renderTableHeader = () => {
    return (
      <TableRow>
        {headers.map(header => (
          <TableCell
            align="center"
            key={header.dataKey}
            sx={{ backgroundColor: 'background.paper', minWidth: '100px' }}
          >
            {header.label}
          </TableCell>
        ))}
      </TableRow>
    )
  }

  const renderTableItem = (_, data) => {
    return (
      <>
        {headers.map(header => (
          <TableCell key={header.dataKey} align="center">
            {header.dataKey === 'imageUrl' && (
              <img
                src={data['imageUrl']}
                alt={data['name']}
                width={80}
                height={80}
              />
            )}

            {(header.dataKey === 'name' || header.dataKey === 'description') &&
              data[header.dataKey]}
            {header.dataKey === 'isShow' && (
              <Switch
                checked={data[header.dataKey]}
                onChange={event =>
                  onClickToggle(event.target.checked, data['_id'])
                }
              />
            )}
          </TableCell>
        ))}
      </>
    )
  }

  return (
    <>
      <Table
        headers={headers}
        data={subBadges}
        headerComponent={renderTableHeader}
        itemContent={renderTableItem}
        tabs={tabs}
        selected="sub"
        onClickTab={onClickTab}
      />
    </>
  )
}
