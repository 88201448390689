import axios from 'axios'
import * as Cookie from '../lib/cookie'

const client = axios.create({
  baseURL: process.env.REACT_APP_SERVER_API_URL,
})

client.interceptors.request.use(function (config) {
  const access_token = Cookie.getCookie('NABBA_ADMIN_ACCESS_TOKEN')
  const refresh_token = Cookie.getCookie('NABBA_ADMIN_REFLESH_TOKEN')
  if (access_token && refresh_token) {
    config.headers['Authorization'] = `Bearer ${access_token}`
    config.headers['refreshtoken'] = refresh_token
  }
  return config
})

export default client
