import {
  Box,
  Button,
  Card,
  Chip,
  Skeleton,
  Stack,
  Switch,
  Typography,
} from '@mui/material'
import dayjs from 'dayjs'
import React from 'react'

export default function BannerItem({
  id,
  index,
  closingDate,
  isActive,
  pcImage,
  onChangeToggle,
  onClickDelete,
  onClickModify,
}) {
  const handleChange = (event, id) => {
    const {
      target: { checked },
    } = event

    onChangeToggle(checked, id)
  }
  return (
    <Card sx={{ padding: 1 }}>
      <Stack gap={1}>
        <Stack
          direction="row"
          justifyContent="space-between"
          sx={{ width: '100%', height: '100%' }}
        >
          <Chip color="primary" label={index || 'n'} />
          <Switch
            onChange={event => handleChange(event, id)}
            checked={isActive}
          />
        </Stack>
        <Box>
          {closingDate ? (
            <Typography variant="caption">
              {dayjs(closingDate)
                .locale('ko')
                .format('노출 종료일 : YYYY년 MM월 DD일')}
            </Typography>
          ) : (
            <Skeleton variant="rounded" height="20px" />
          )}
        </Box>
        <Box sx={{ width: '100%', paddingTop: '31.81%', position: 'relative' }}>
          {pcImage ? (
            <img
              src={pcImage}
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                objectFit: 'cover',
              }}
              alt="배너 이미지"
            />
          ) : (
            <Skeleton
              variant="rounded"
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
              }}
            />
          )}
        </Box>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="flex-end"
          gap={1}
        >
          <Button color="error" size="large" onClick={() => onClickDelete(id)}>
            삭제
          </Button>
          <Button
            color="primary"
            size="large"
            onClick={() => onClickModify('edit', id)}
          >
            수정
          </Button>
        </Stack>
      </Stack>
    </Card>
  )
}
