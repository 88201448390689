import React from 'react'
import { Stack, Typography } from '@mui/material'
import Logo from '../../atoms/Logo/Logo'
export default function Ci() {
  return (
    <Stack direction="row" spacing={1} sx={{ color: 'text.primary' }}>
      <Logo size="s" fill="#ffffff" />
      <Typography variant="h6">관리자페이지</Typography>
    </Stack>
  )
}
