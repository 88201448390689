import React from 'react'
import { ReactComponent as LogoS } from '../../../assets/svg/logo/LogoS.svg'
import { ReactComponent as LogoM } from '../../../assets/svg/logo/LogoM.svg'
import { ReactComponent as LogoL } from '../../../assets/svg/logo/LogoL.svg'
import { ReactComponent as LogoXL } from '../../../assets/svg/logo/LogoXL.svg'
import { ReactComponent as LogoXXL } from '../../../assets/svg/logo/LogoXXL.svg'

export default function Logo({ size, fill }) {
  const renderLogo = size => {
    switch (size) {
      case 's':
        return <LogoS fill={fill} />
      case 'm':
        return <LogoM fill={fill} />
      case 'l':
        return <LogoL fill={fill} />
      case 'xl':
        return <LogoXL fill={fill} />
      case 'xxl':
        return <LogoXXL fill={fill} />
      default:
        return <LogoS fill={fill} />
    }
  }
  return <>{renderLogo(size)}</>
}
