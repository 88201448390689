import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material'
import React from 'react'

export default function ConfirmDialog({
  open,
  title,
  content,
  onClickCancel,
  onClickConfirm,
  cancelLabel,
  confirmLabel,
}) {
  return (
    <Dialog
      open={open}
      onClose={onClickCancel}
      aria-labelledby={title}
      aria-describedby={content}
    >
      <DialogTitle id={title}>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id={content}>{content}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClickCancel} color="error">
          {cancelLabel || '취소'}
        </Button>
        <Button onClick={onClickConfirm} autoFocus>
          {confirmLabel || '확인'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
