import React from 'react'
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableContainer,
  Paper,
} from '@mui/material'
import { TableVirtuoso } from 'react-virtuoso'

const TScroller = React.forwardRef((props, ref) => (
  <TableContainer component={Paper} {...props} ref={ref} />
))

const TTable = props => <Table {...props} sx={{ borderCollapse: 'separate' }} />

const TRow = ({ item: _item, ...props }) => {
  return (
    <TableRow
      {...props}
      sx={{
        cursor: 'pointer',
        '&:hover': {
          backgroundColor: 'action.hover',
          opacity: [0.9, 0.8, 0.7],
        },
      }}
    />
  )
}

const TBody = React.forwardRef((props, ref) => (
  <TableBody {...props} ref={ref} />
))

export default function VirtualizedTable({ data, headerComponent, ...rest }) {
  return (
    <TableVirtuoso
      style={{ height: '100%' }}
      data={data}
      components={{
        Scroller: TScroller,
        Table: TTable,
        TableHead: TableHead,
        TableRow: TRow,
        TableBody: TBody,
        // EmptyPlaceholder: () => (
        //   <tbody>
        //     <tr>
        //       <td colSpan={4}>결과가 없습니다.</td>
        //     </tr>
        //   </tbody>
        // ),
      }}
      fixedHeaderContent={headerComponent}
      {...rest}
    />
  )
}
