import { v4 as uuidv4 } from 'uuid'

export const getUniqueId = () => {
  return uuidv4()
}

// @ts-check
/**
 * 배열 arr의 i 번째 요소와 j 번째 요소를 바꿔 새로운 배열을 반환한다.
 * @param {Array} arr
 * @param {number} i
 * @param {number} j
 * @returns Array
 */

export const swapArrayItem = (arr, i, j) => {
  ;[arr[i], arr[j]] = [arr[j], arr[i]]
  return arr
}
