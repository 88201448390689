import React from 'react'
import { ReactComponent as NaverLogo } from '../../../assets/svg/snsLogo/Naver.svg'
import { ReactComponent as KakaoLogo } from '../../../assets/svg/snsLogo/Kakao.svg'
import { ReactComponent as GoogleLogo } from '../../../assets/svg/snsLogo/Google.svg'

export default function SnsLogo({ service, width = 40, height = 40 }) {
  return (
    <>
      {service === 'naver' && <NaverLogo width={width} height={height} />}
      {service === 'kakao' && <KakaoLogo width={width} height={height} />}
      {service === 'google' && <GoogleLogo width={width} height={height} />}
    </>
  )
}
