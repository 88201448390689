import { Cookies } from 'react-cookie'

const cookies = new Cookies()

export const setCookie = (key, value, option) => {
  const today = new Date()
  const expireDate = new Date(today.setDate(today.getDate() + 365))

  return cookies.set(key, value, {
    ...option,
    expires: expireDate,
  })
}

export const getCookie = key => {
  return cookies.get(key)
}

export const removeCookie = key => {
  return cookies.remove(key)
}
