export const headers = [
  {
    label: '배지이미지',
    dataKey: 'imageUrl',
  },
  {
    label: '이름',
    dataKey: 'name',
  },
  {
    label: '설명',
    dataKey: 'description',
  },
  {
    label: '활성화',
    dataKey: 'isShow',
  },
]

const sample = [
  [
    'https://cccv-to.s3.ap-northeast-2.amazonaws.com/files/badgeImage/NNZ1Vcz8sCmUazDrbEYk_WbqQsRpeXQ.png',
    '2022 AOC Bodybuilding ',
    '나바코리아 PRO 입상자에게 주어지는 배지입니다. 나바코리아 PRO 입상자에게 주어지는 배지입니다.나바코리아 PRO 입상자에게 주어지는 배지입니다.나바코리아 PRO 입상자에게 주어지는 배지입니다.나바코리아 PRO 입상자에게 주어지는 배지입니다.나바코리아 PRO 입상자에게 주어지는 배지입니다.나바코리아 PRO 입상자에게 주어지는 배지입니다.',
    true,
  ],
  [
    'https://cccv-to.s3.ap-northeast-2.amazonaws.com/files/badgeImage/NNZ1Vcz8sCmUazDrbEYk_WbqQsRpeXQ.png',
    '2022 AOC MISS FIGURE ',
    '나바코리아 PRO 입상자에게 주어지는 배지입니다. 왼쪽오른쪽돌려돌려',
    true,
  ],
  [
    'https://cccv-to.s3.ap-northeast-2.amazonaws.com/files/badgeImage/NNZ1Vcz8sCmUazDrbEYk_WbqQsRpeXQ.png',
    '2022 AOC BIKINI MODEL',
    '나바코리아 PRO 입상자에게 주어지는 배지입니다. 안녕하세요...',
    false,
  ],
  [
    'https://cccv-to.s3.ap-northeast-2.amazonaws.com/files/badgeImage/NNZ1Vcz8sCmUazDrbEYk_WbqQsRpeXQ.png',
    '2022 AOC BERMUDA MODEL',
    '나바코리아 PRO 입상자에게 주어지는 배지입니다. 안녕하세요안녕하세...',
    false,
  ],
]

function createData(_id, badgeImage, name, description, isActive) {
  return { _id, badgeImage, name, description, isActive }
}

export const sampleData = Array.from({ length: 100 }, (_, index) => {
  const randomSelection = sample[Math.floor(Math.random() * sample.length)]
  return createData(index, ...randomSelection)
})
