import React, { Suspense } from 'react'
import Sidebar from '../../organism/Sidebar/Sidebar'
import { Outlet } from 'react-router-dom'
import { Box, Stack } from '@mui/material'
import { grey } from '@mui/material/colors'
export default function CommonLayout() {
  const bgColor = grey[800]

  return (
    <Stack
      direction="row"
      sx={{
        height: '100%',
        backgroundColor: 'background.paper',
      }}
    >
      <Box
        sx={{
          flex: '0 0 364px',
        }}
      >
        <Sidebar />
      </Box>

      <Box
        sx={{
          backgroundColor: bgColor,
          flex: '1 1 auto',
          overflow: 'auto',
        }}
      >
        <Suspense fallback={<div>Main Suspense Test</div>}>
          <Outlet />
        </Suspense>
      </Box>
    </Stack>
  )
}
