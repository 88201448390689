import {
  Container,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material'
import React from 'react'
import Searchbar from '../../../molecules/Searchbar/Searchbar'
import VirtualizedTable from '../../../molecules/VirtualizedTable/VirtualizedTable'

export default function Table({
  headers,
  data,
  searchbarProps = {
    hasSearchbarFilter: false,
    menus: [],
  },
  tabs = [],
  selected,
  onClickTab,
  headerComponent,
  ItemComponent,
  ...rest
}) {
  const renderToggleButton = () => {
    if (tabs.length <= 0) return null

    return (
      <ToggleButtonGroup
        color="primary"
        value={selected}
        exclusive
        aria-label="filter"
        sx={{
          flexShrink: 0,
          mr: 8,
        }}
      >
        {tabs.map((item, index) => (
          <ToggleButton
            key={index}
            value={item.value}
            onClick={() => onClickTab(item.value)}
          >
            {item.label}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    )
  }
  return (
    <Container sx={{ height: '100%' }}>
      <Stack py={5} gap={5} sx={{ height: '100%' }}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          {renderToggleButton()}
          <Searchbar {...searchbarProps} />
        </Stack>
        <VirtualizedTable
          headers={headers}
          data={data}
          headerComponent={headerComponent}
          {...rest}
        />
      </Stack>
    </Container>
  )
}
