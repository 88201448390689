import React, { useEffect, useState } from 'react'
import DraggableGrid from '../../../components/template/Container/DraggableGrid/DraggableGrid'
import AddGridItemModal from '../../../components/organism/AddGridItemModal/AddGridItemModal'
import ConfirmDialog from '../../../components/molecules/ConfirmDialog/ConfirmDialog'
import FilterToggleButtonHeader from '../../../components/molecules/FilterToggleButtonHeader/FilterToggleButtonHeader'
import { filtersConfig, toggleConfig } from './config'
import CardItem from '../../../components/molecules/CardItem/CardItem'
import {
  getCurations,
  updateCurationsItem,
  updateCurationsSortOrder,
} from '../../../api/curation'
import { getUserByPageId } from '../../../api/user'
import { useToastContext } from '../../../context/ToastContext'

export default function Pro() {
  const [filters, setFilters] = useState(filtersConfig)
  const [curation, setCuration] = useState()
  const [sortOrder, setSortOrder] = useState()
  const [pro, setPro] = useState()
  const [addModalConfig, setAddModalConfig] = useState({
    isOpen: false,
    isModify: false,
  })
  const [isOpenConfirmDialog, setIsOpenConfirmDialog] = useState(false)
  const [updatePro, setUpdatePro] = useState({
    _id: '',
    description: '',
    customImage: '',
    index: 0,
    isActive: true,
  })
  const { addToast } = useToastContext()

  const handleFilter = value => {
    if (!value) return
    const updated = filters.map(item =>
      item?.value === value
        ? { ...item, isSelected: true }
        : { ...item, isSelected: false }
    )
    setFilters(updated)
  }

  const handleSortOrder = async value => {
    try {
      if (!value) return
      const updated = sortOrder.map(item =>
        item?.value === value
          ? { ...item, isSelected: true }
          : { ...item, isSelected: false }
      )
      await updateCurationsSortOrder(curation.title, value)
      setSortOrder(updated)
    } catch (e) {
      addToast({
        message: `${e.response.data.error || e.response.data.message || e}`,
        severity: 'error',
      })
    }
  }

  const resetUpdatePro = () => {
    setUpdatePro({ _id: '', description: '', index: 0, isActive: true })
  }

  const handleAddModal = (status, _id) => {
    if (status === 'open') {
      setAddModalConfig({
        isOpen: true,
        isModify: false,
      })
      setUpdatePro({ ...updatePro, index: pro.length })
    } else if (status === 'close') {
      setAddModalConfig({
        isOpen: false,
        isModify: false,
      })
      resetUpdatePro()
    } else if (status === 'edit') {
      setAddModalConfig({
        isOpen: true,
        isModify: true,
      })
      const selected = pro.filter(item => item._id === _id)[0]
      setUpdatePro(selected)
    } else {
      addToast({
        message: `알수없는 status 입니다.`,
        severity: 'error',
      })
    }
  }

  const handleChangeOrder = async newpro => {
    try {
      const updated = newpro.map((item, index) => ({ ...item, index }))
      await updateCurationsItem(curation.title, updated)

      setPro(await injectPageInfo(updated))
    } catch (e) {
      addToast({
        message: `${e.response.data.error || e.response.data.message}`,
        severity: 'error',
      })
    }
  }

  const handleToggle = async (checked, id) => {
    try {
      if (!id) return

      const updated = pro.map(item =>
        item._id === id ? { ...item, isActive: checked } : { ...item }
      )
      await updateCurationsItem(curation.title, updated)
      setPro(updated)
    } catch (e) {
      addToast({
        message: `${e.response.data.error || e.response.data.message}`,
        severity: 'error',
      })
    }
  }

  const handleClickDelete = _id => {
    const selected = pro.filter(item => item._id === _id)[0]
    setUpdatePro(selected)
    setIsOpenConfirmDialog(true)
  }

  const handleDelete = async _id => {
    try {
      if (!_id) return

      const updated = pro
        .filter(item => item._id !== _id)
        .map((item, index) => ({ ...item, index }))

      await updateCurationsItem(curation.title, updated)
      setPro(updated)
      closeDeleteConfirm()
    } catch (e) {
      addToast({
        message: `${e.response.data.error || e.response.data.message}`,
        severity: 'error',
      })
    }
  }

  const closeDeleteConfirm = () => {
    setIsOpenConfirmDialog(false)
    resetUpdatePro()
  }

  const handleUpdatePro = (property, value) => {
    setUpdatePro(prev => ({ ...prev, [property]: value }))
  }

  const extractPageIdFromUrl = url => {
    const split = url.split('/')
    return split[split.length - 1]
  }

  const fetchUpdateProInfo = async () => {
    try {
      if (!updatePro._id) {
        setUpdatePro({ _id: '', description: updatePro.description })
        return
      }
      const _pageId = extractPageIdFromUrl(updatePro._id)
      const {
        data: {
          data: { profileImgUrl, name, pageId },
        },
      } = await getUserByPageId(_pageId)
      handleUpdatePro('profileImgUrl', profileImgUrl)
      handleUpdatePro('name', name)
      handleUpdatePro('pageId', pageId)
    } catch (e) {
      addToast({
        message: `${e.response.data.error || e.response.data.message}`,
        severity: 'error',
      })
    }
  }

  const handleSubmit = async isModify => {
    try {
      const { _id, description, index, isActive, customImage } = updatePro
      let updated
      if (isModify) {
        updated = pro.map(item =>
          item._id === _id
            ? {
                _id: extractPageIdFromUrl(_id),
                title: extractPageIdFromUrl(_id),
                description,
                index,
                isActive,
                customImage,
              }
            : { ...item }
        )
      } else {
        updated = pro.concat({
          _id: extractPageIdFromUrl(_id),
          title: extractPageIdFromUrl(_id),
          description,
          index,
          isActive,
          customImage,
        })
      }

      await updateCurationsItem(curation.title, updated)
      setPro(await injectPageInfo(updated))
      handleAddModal('close')
    } catch (e) {
      addToast({
        message: `${e.response.data.error || e.response.data.message || e}`,
        severity: 'error',
      })
    }
  }

  const injectPageInfo = pro => {
    if (!pro) return
    return Promise.all(
      pro.map(async item => {
        const {
          data: {
            data: { profileImgUrl, name, pageId },
          },
        } = await getUserByPageId(item.title)
        return {
          ...item,
          _id: item.title,
          profileImgUrl,
          name,
          pageId,
        }
      })
    )
  }

  const ascendingArray = (array, standard) => {
    return array?.sort((a, b) => a[standard] - b[standard])
  }

  useEffect(() => {
    try {
      async function fetchCurations() {
        const { data } = await getCurations('pro')
        setCuration(data)
        setSortOrder(
          toggleConfig.map(item =>
            item?.value === data.sortOrder
              ? { ...item, isSelected: true }
              : { ...item, isSelected: false }
          )
        )

        const pro = await injectPageInfo(ascendingArray(data.items, 'index'))
        setPro(pro)
      }
      fetchCurations()
    } catch (e) {
      console.error(e)
    }
  }, [])

  const Header = () => {
    return (
      <FilterToggleButtonHeader
        filters={filters}
        onChangeFilter={handleFilter}
        toggle={sortOrder || toggleConfig}
        onChangeViewMethods={handleSortOrder}
        onClickAddButton={() => handleAddModal('open')}
        buttonLabel="역대 PRO 추가"
      />
    )
  }

  const GridItem = item => {
    return (
      <CardItem
        id={item?._id}
        index={item?.index + 1}
        isActive={item?.isActive}
        caption={'최고 성적'}
        description={item?.description}
        title={item?.name || item?.pageId}
        avatar={item?.profileImgUrl}
        backgroundImage={item?.customImage}
        onChangeToggle={handleToggle}
        onClickDelete={() => handleClickDelete(item._id)}
        onClickModify={() => handleAddModal('edit', item._id)}
      />
    )
  }

  return (
    <>
      <DraggableGrid
        filters={filters}
        headerComponent={Header}
        itemConponent={GridItem}
        data={pro || []}
        onChangeOrder={handleChangeOrder}
      />
      <AddGridItemModal
        type={curation?.type}
        title="역대 PRO 추가"
        open={addModalConfig.isOpen}
        isModify={addModalConfig.isModify}
        handleClose={() => handleAddModal('close')}
        caption="최고 성적"
        handleSubmit={handleSubmit}
        item={updatePro}
        handleChange={handleUpdatePro}
        handleBlur={fetchUpdateProInfo}
      />
      <ConfirmDialog
        open={isOpenConfirmDialog}
        title="역대 PRO 삭제"
        content={`역대 PRO ${
          updatePro.name || updatePro.pageId
        } 를 삭제합니다. 삭제하시겠습니까?`}
        onClickCancel={closeDeleteConfirm}
        onClickConfirm={() => handleDelete(updatePro._id)}
      />
    </>
  )
}
