import React, { useEffect, useState } from 'react'
import { Box, Container, Stack, Grid } from '@mui/material'
import { SortableContainer, SortableElement } from 'react-sortable-hoc'
import { swapArrayItem } from '../../../../utils/helper'

const GridItem = SortableElement(({ item, itemConponent }) => (
  <Grid item lg={3} md={4} sm={6} xs={12}>
    {itemConponent(item)}
  </Grid>
))

const SortableGrid = SortableContainer(
  ({ items, itemConponent, selectedFilter, isBanner }) => {
    return (
      <Grid container sx={{ height: '100%' }} spacing={2}>
        {isBanner && (
          <>
            {selectedFilter === 'total' &&
              items.map((item, index) => (
                <GridItem
                  key={`${item._id || item.bannerId}`}
                  index={index}
                  item={item}
                  itemConponent={itemConponent}
                />
              ))}
            {selectedFilter !== 'total' &&
              items
                .filter(item => {
                  if (selectedFilter === 'active') {
                    return item.status
                  } else {
                    return !item.status
                  }
                })
                .map((item, index) => (
                  <GridItem
                    key={`${item._id || item.bannerId}`}
                    index={index}
                    item={item}
                    itemConponent={itemConponent}
                  />
                ))}
          </>
        )}
        {!isBanner && (
          <>
            {selectedFilter === 'total' &&
              items.map((item, index) => (
                <GridItem
                  key={`${item._id || item.bannerId}`}
                  index={index}
                  item={item}
                  itemConponent={itemConponent}
                />
              ))}
            {selectedFilter !== 'total' &&
              items
                .filter(item => {
                  if (selectedFilter === 'active') {
                    return item.isActive
                  } else {
                    return !item.isActive
                  }
                })
                .map((item, index) => (
                  <GridItem
                    key={`${item._id || item.bannerId}`}
                    index={index}
                    item={item}
                    itemConponent={itemConponent}
                  />
                ))}
          </>
        )}
      </Grid>
    )
  }
)

export default function DraggableGrid({
  filters,
  headerComponent,
  data,
  itemConponent,
  onChangeOrder,
  isBanner,
}) {
  const [selectedFilter, setSelectedFilter] = useState()

  const onSortEnd = ({ oldIndex, newIndex }) => {
    const newItem = swapArrayItem(data, oldIndex, newIndex)
    if (onChangeOrder) onChangeOrder(newItem)
  }

  useEffect(() => {
    if (!filters) return
    const selected = filters.filter(item => item.isSelected)[0].value
    setSelectedFilter(selected)
  }, [filters])

  return (
    <Container
      sx={{
        py: 4,
      }}
    >
      <Stack>
        {headerComponent && <Box py={1}>{headerComponent()}</Box>}
        <SortableGrid
          selectedFilter={selectedFilter}
          items={data}
          onSortEnd={onSortEnd}
          axis="xy"
          itemConponent={itemConponent}
          isBanner={isBanner}
        />
      </Stack>
    </Container>
  )
}
