import { Box } from '@mui/material'
import React from 'react'

export default function Box16by9() {
  return (
    <Box
      sx={{
        width: '100%',
        backgroundColor: 'action.active',
        paddingTop: '56.25%',
      }}
    >
      Box16by9
    </Box>
  )
}
