import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import reportWebVitals from './reportWebVitals'
import { RouterProvider } from 'react-router-dom'
import router from './routes'
import { ThemeProvider } from '@mui/material/styles'
import theme from './themes'
import { ToastContextProvider } from './context/ToastContext'
import { AuthContextProvider } from './context/AuthContext'

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
  <AuthContextProvider>
    <ThemeProvider theme={theme()}>
      <ToastContextProvider>
        <RouterProvider router={router} />
      </ToastContextProvider>
    </ThemeProvider>
  </AuthContextProvider>
)

reportWebVitals()
