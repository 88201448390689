import { IconButton, Switch, TableCell, TableRow } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import Table from '../../../../components/template/Container/Table/Table'
import { tabConfig } from '../tabConfig'
import { headers } from './config'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import InsertLinkIcon from '@mui/icons-material/InsertLink'
import UserInfoModal from '../../../../components/organism/UserInfoModal/UserInfoModal'
import {
  getRequestBages,
  updateRequestStatus,
  rejectRequest,
  putNabbaRequestBadges,
} from '../../../../api/sbt'
import dayjs from 'dayjs'
import { getVerifictionsByPageId } from '../../../../api/user'

export default function Request() {
  const [tabs] = useState(tabConfig)
  const [request, setRequest] = useState()
  const [userInfo, setUserInfo] = useState(null)
  const navigate = useNavigate()
  const { sbtId } = useParams()

  const onClickTab = value => {
    navigate(`/nft/sbt/${sbtId}/${value}`)
  }

  const onClickPage = pageId => {
    window.open(`${process.env.REACT_APP_CCCV_URL}/${pageId}`)
  }

  const onClickUserInfo = async data => {
    const {
      data: { data: verifictions },
    } = await getVerifictionsByPageId(data.pageId)
    const userInfo = {
      pageId: `${process.env.REACT_APP_CCCV_URL}/${data.pageId}`,
      email: data.user_email,
      name: verifictions?.phone.name,
      phoneNumber: verifictions?.phone.phoneNumber,
      birthday: verifictions?.phone.birthday,
    }
    setUserInfo(userInfo)
  }

  const closeUserInfoModal = () => {
    setUserInfo(null)
  }

  /**
   *
   * @param {*} checked - toggle flag
   * @param {*} dataKey - 반려 or 활성화 구분 값
   * @param {*} id - 요청 아이디
   * @param {*} badgeId - 뱃지 서브아이디
   * @param {*} badgeSubId - 뱃지 서브아이디
   * @param {*} userId - User ID
   * @param {*} name - ?
   */
  const onClickToggle = async (
    checked,
    id,
    badgeId,
    badgeSubId,
    userId,
    dataKey,
    name
  ) => {
    const updated = request.map(item =>
      item._id === id ? { ...item, [dataKey]: checked } : { ...item }
    )

    if (dataKey === 'isAdminShow') {
      if (checked === true) {
        // 뱃지 부여
        await putNabbaRequestBadges({
          id: id,
          badgeId: badgeId,
          subItem: badgeSubId,
          userId: userId,
        })
      } else {
        // 뱃지 비활성화
        await rejectRequest(id, `${name} 배지 신청이 거절되었습니다.`)
      }
    }

    if (dataKey === 'refuse') {
      await updateRequestStatus(id, `${name} 배지 신청이 반려되었습니다.`)
    }

    setRequest(updated)
  }

  useEffect(() => {
    async function getRequest() {
      const {
        data: { data },
      } = await getRequestBages(sbtId)

      setRequest(
        data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
      )
    }
    getRequest()
  }, [sbtId])

  const renderTableHeader = () => {
    return (
      <TableRow>
        {headers.map(header => (
          <TableCell
            align="center"
            key={header.dataKey}
            sx={{ backgroundColor: 'background.paper' }}
          >
            {header.label}
          </TableCell>
        ))}
      </TableRow>
    )
  }

  const renderTableItem = (_, data) => {
    return (
      <>
        {headers.map(header => (
          <TableCell key={header.dataKey} align="center">
            {header.dataKey === 'userInfo' && (
              <IconButton
                aria-label="userInfo"
                onClick={() => onClickUserInfo(data)}
              >
                <AccountCircleIcon />
              </IconButton>
            )}

            {header.dataKey === 'pageId' && data['pageId'] && (
              <IconButton
                aria-label="page"
                onClick={() => onClickPage(data['pageId'])}
              >
                <InsertLinkIcon />
              </IconButton>
            )}

            {(header.dataKey === 'name' || header.dataKey === 'memo') &&
              data[header.dataKey]}
            {header.dataKey === 'createdAt' &&
              dayjs(data[header.dataKey])
                .locale('ko')
                .format('YYYY년 MM월 DD일')}
            {(header.dataKey === 'refuse' ||
              header.dataKey === 'isAdminShow') && (
              <Switch
                checked={data[header.dataKey]}
                onChange={event =>
                  onClickToggle(
                    event.target.checked,
                    data['_id'],
                    data['badge_id'],
                    data['subItem_id'],
                    data['user_id'],
                    header.dataKey,
                    data['name']
                  )
                }
              />
            )}
          </TableCell>
        ))}
      </>
    )
  }

  return (
    <>
      {request && (
        <Table
          headers={headers}
          data={request}
          headerComponent={renderTableHeader}
          itemContent={renderTableItem}
          tabs={tabs}
          selected="request"
          onClickTab={onClickTab}
        />
      )}
      <UserInfoModal userInfo={userInfo} handleClose={closeUserInfoModal} />
    </>
  )
}
