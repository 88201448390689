import {
  Stack,
  Typography,
  Box,
  Skeleton,
  Avatar,
  Chip,
  Link,
} from '@mui/material'

/**
 * pageInfo
 * pageInfo.
 */

/**
 * 유저 상세보기 상단정보
 */
export default function UserProfile({
  NFTAndSBTDataArray,
  LinkArray,
  pageInfo,
  permission: [userPermissionStatus, setUserPermissionStatus],
}) {
  return (
    <Stack
      className="UserProfile"
      direction="row"
      justifyContent="space-between"
      alignItems="flex-end"
      gap={2}
    >
      <Stack
        direction="row"
        alignItems="center"
        sx={{
          flex: '1 1 auto',
        }}
        gap={2}
      >
        <Box sx={{ height: 150, flex: '0 0 150px' }}>
          {pageInfo === null ? (
            <Skeleton variant="circular">
              <Avatar sx={{ width: '150px', height: '150px' }} />
            </Skeleton>
          ) : (
            <Avatar
              src={pageInfo.profileImgUrl}
              sx={{ width: '150px', height: '150px' }}
            />
          )}
        </Box>
        <Stack
          gap={1}
          sx={{
            flex: '1 1 0',
          }}
        >
          <Stack direction="row">
            {pageInfo === null ? (
              <Skeleton
                height={24}
                sx={{
                  flex: '1 0 auto',
                }}
              />
            ) : (
              Object.values(userPermissionStatus)
                .filter(role => role.value)
                .map(filteredRole => {
                  return (
                    <Chip
                      key={filteredRole.role}
                      label={filteredRole.text}
                      size="small"
                      color="primary"
                      sx={{
                        '& + &': {
                          marginLeft: 1,
                        },
                      }}
                    />
                  )
                })
            )}
          </Stack>
          <Typography
            variant="h5"
            sx={{
              wordBreak: 'break-all',
            }}
          >
            {pageInfo === null ? <Skeleton /> : pageInfo.pageId}
          </Typography>
          <Typography
            variant="subtitle1"
            sx={{
              wordBreak: 'break-all',
            }}
          >
            {pageInfo === null ? <Skeleton /> : pageInfo.name}
          </Typography>
          <Stack direction="row">
            {/* CCCV Link, CCCV NFT 조건부 렌더링 */}
            {pageInfo === null
              ? LinkArray.map(data => (
                  <Skeleton
                    key={data.key}
                    sx={{
                      '& + &': {
                        marginLeft: 1,
                      },
                    }}
                  >
                    <Link>{data.label}</Link>
                  </Skeleton>
                ))
              : LinkArray.map(data => (
                  <Link
                    key={data.key}
                    sx={{
                      '& + &': {
                        marginLeft: 1,
                      },
                    }}
                    href={data.href}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {data.label}
                  </Link>
                ))}
          </Stack>
        </Stack>
      </Stack>
      <Stack
        sx={{
          flex: '0 0 100px',
        }}
      >
        <Stack
          direction="row"
          sx={{
            flex: '1 1 auto',
          }}
        >
          {pageInfo === null
            ? NFTAndSBTDataArray.map(data => (
                <Skeleton
                  key={data.key}
                  sx={{
                    '& + &': {
                      marginLeft: 1,
                    },
                  }}
                >
                  <Chip
                    label={`${data.value} ${data.label}`}
                    variant="outlined"
                    size="small"
                    color="primary"
                  />
                </Skeleton>
              ))
            : NFTAndSBTDataArray.map(data => (
                <Chip
                  key={data.key}
                  label={`${data.value} ${data.label}`}
                  variant="outlined"
                  size="small"
                  color="primary"
                  sx={{
                    '& + &': {
                      marginLeft: 1,
                    },
                  }}
                />
              ))}
        </Stack>
      </Stack>
    </Stack>
  )
}
