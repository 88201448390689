import { useState } from 'react'
import {
  Collapse,
  Stack,
  ListItemButton,
  Icon,
  ListItemIcon,
  ListItemText,
  IconButton,
} from '@mui/material'
import { useLocation, matchPath } from 'react-router-dom'

import ListItem from '../ListItem/ListItem'

export default function NestedList({
  id,
  text = 'Nested List',
  iconName = 'star_filled',
  nestedlist,
  selected = false,
  wrapperProps,
  nestedlistitemprops,
}) {
  const { pathname: currentPathName } = useLocation()
  const [open, setOpen] = useState(selected)

  const handleOpen = () => {
    setOpen(!open)
  }

  /**
   * 드롭다운 아이콘버튼 렌더링
   */
  const renderIconButton = () => {
    return open ? (
      <IconButton>
        <Icon>arrow_drop_up_filled</Icon>
      </IconButton>
    ) : (
      <IconButton>
        <Icon>arrow_drop_down_filled</Icon>
      </IconButton>
    )
  }

  return (
    <Stack {...wrapperProps} sx={{ color: 'text.primary' }}>
      <ListItemButton onClick={handleOpen} selected={selected} id={id}>
        <ListItemIcon>
          <Icon>{iconName}</Icon>
        </ListItemIcon>
        <ListItemText primary={text} />
        {renderIconButton()}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        {nestedlist.map(item => {
          return (
            <ListItem
              key={item.text}
              to={item.path}
              text={item.text}
              iconName={item.iconName}
              selected={
                matchPath(item.pathRegex || '', currentPathName) === null
                  ? false
                  : true
              }
              {...nestedlistitemprops}
            />
          )
        })}
      </Collapse>
    </Stack>
  )
}
