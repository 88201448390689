export default function componentStyleOverrides(theme) {
  return {
    MuiListItemButton: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            '& .MuiListItemIcon-root': {
              color: theme.palette.primary.main,
            },
            '& .MuiListItemText-root': {
              color: theme.palette.primary.main,
            },
          },
        },
      },
    },
  }
}
