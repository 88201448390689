import client from './client'

export const getCurations = title => client.get(`/curation/title/${title}`)
export const updateCurationsItem = (title, items) =>
  client.patch(`/admins-customers/nabba/curation/title/${title}/items`, items)
export const updateCurationsSortOrder = (title, order) =>
  client.patch(`/curation/title/${title}/sort-order`, {
    order,
  })

export const uploadCurationBgImage = fd =>
  client.post('/admins-customers/nabba/curation/background-image', fd, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
