import { Container, Stack, Box, CircularProgress } from '@mui/material'
import { Suspense } from 'react'
import { Outlet, useOutletContext } from 'react-router-dom'

// Component
import LinkTabs from '../../molecules/LinkTabs/LinkTabs'
import UserProfile from '../../organism/UserProfile/UserProfile'

// Route
import routerRegex from '../../../routes/routerRegex'

export default function DetailTemplate({
  pageInfo,
  detailTabInfo,
  userPermissionStatus,
}) {
  const TABLINK_PATH = routerRegex.user.detail.path.replace(
    /:userId/g,
    pageInfo && pageInfo.user_id
  )

  /**
   * SBT, NFT 개수 데이터
   */
  const UserProfileNFTAndSBTDataArray = [
    { key: 0, label: 'SBT', value: (pageInfo && pageInfo.CountSBT) || 0 },
    { key: 1, label: 'NFT', value: (pageInfo && pageInfo.CountNFT) || 0 },
  ]

  /**
   * User Link, NFT 링크 데이터
   */
  const UserProfileLink = [
    {
      key: 0,
      label: 'CCCV Link',
      href: `${process.env.REACT_APP_CCCV_URL}/${pageInfo && pageInfo.pageId}`,
    },
    {
      key: 1,
      label: 'CCCV NFT',
      href: `${process.env.REACT_APP_CCCV_URL}/nft/user/${
        pageInfo && pageInfo.pageId
      }`,
    },
  ]

  /**
   * Detail Tab 링크 데이터
   */
  const TabLink = [
    {
      key: 0,
      label: '기본정보',
      path: TABLINK_PATH,
    },
    {
      key: 1,
      label: '보유SBT',
      path: `${TABLINK_PATH}/${routerRegex.user.detail.sbtList.path}`,
    },
  ]

  const renderTabLink = () => {
    if (!pageInfo) return null
    return <LinkTabs tabs={TabLink} />
  }

  return (
    <Container sx={{ border: '1px solid black', height: '100%', py: 4 }}>
      <Stack>
        <Box
          sx={{
            flex: '0 0 291px',
          }}
        >
          <UserProfile
            NFTAndSBTDataArray={UserProfileNFTAndSBTDataArray}
            LinkArray={UserProfileLink}
            pageInfo={pageInfo}
            permission={userPermissionStatus}
          />
        </Box>
        <Box
          className="UserDetail__ContentWrapper"
          sx={{
            flex: '1 1 0',
          }}
        >
          {/* 유저 디테일 탭 메뉴 */}
          {renderTabLink()}
          {/* !-- 유저 디테일 탭 메뉴 */}
          <Stack
            className="UserDetail__ContentFlexBox"
            direction="row"
            gap={3}
            sx={{
              px: 3,
              py: 6,
              backgroundColor: 'background.default',
            }}
          >
            {/*
              Tab Contents 영역
              UserDefaultInfoTab - 기본정보
              UserSBTInfoTab - SBT정보
            */}
            <Suspense
              fallback={
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    flex: '1 1 0',
                    py: 10,
                  }}
                >
                  <CircularProgress size="5rem" />
                </Box>
              }
            >
              <Outlet
                context={{ pageInfo, detailTabInfo, userPermissionStatus }}
              />
            </Suspense>
            {/* !-- Tab Contents 영역 */}
          </Stack>
        </Box>
      </Stack>
    </Container>
  )
}

/**
 * Outlet Status
 *
 * @returns {(pageInfo | detailTabInfo | permission)}
 */
export function usePageInfo() {
  return useOutletContext()
}
