import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

// Component
import DetailTemplate from '../../../components/template/User/DetailTemplate'

// API
import {
  getNFTCount as getNFTCountAPI,
  getSBTCount as getSBTCountAPI,
  getBasicInfo as getBasicInfoAPI,
} from '../../../api/pages'

export const PERMISSION_INIT = {
  nabba_super_admin: {
    role: 'nabba_super_admin',
    text: '슈퍼관리자',
    value: false,
  },
  nabba_admin: {
    role: 'nabba_admin',
    text: '관리자',
    value: false,
  },
  nabba: {
    role: 'nabba',
    text: '나바NFT회원',
    value: false,
  },
}
export default function Detail() {
  const { userId } = useParams()
  /**
   * UserProfile 정보
   */
  const [pageInfo, setPageInfo] = useState(null)

  /**
   * Detail Tab 정보
   */
  const [detailTabInfo, setDetailTabInfo] = useState(null)

  /**
   * 권한정보
   */
  const [userPermissionStatus, setUserPermissionStatus] =
    useState(PERMISSION_INIT)

  useEffect(() => {
    /**
     * initialize
     */
    ;(async () => {
      if (userId) {
        /**
         * getPage, getAdminsPageId
         */
        try {
          const [
            { data: defaultTabInfo },
            { data: CountNFT },
            { data: CountSBT },
          ] = await Promise.all([
            getBasicInfoAPI({
              userId: userId,
            }),
            getNFTCountAPI({
              userId: userId,
            }),
            getSBTCountAPI({
              userId: userId,
            }),
          ])

          /**
           * 권한설정
           */
          const isNabbaUser = defaultTabInfo.roles.includes(
            PERMISSION_INIT.nabba.role
          )
          const isAdmin = defaultTabInfo.roles.includes(
            PERMISSION_INIT.nabba_admin.role
          )
          const isSuperAdmin = defaultTabInfo.roles.includes(
            PERMISSION_INIT.nabba_super_admin.role
          )

          setUserPermissionStatus({
            nabba_super_admin: {
              ...PERMISSION_INIT.nabba_super_admin,
              value: isSuperAdmin,
            },
            nabba_admin: {
              ...PERMISSION_INIT.nabba_admin,
              value: isAdmin,
            },
            nabba: {
              ...PERMISSION_INIT.nabba,
              value: isNabbaUser,
            },
          })

          setDetailTabInfo(defaultTabInfo)
          setPageInfo({ ...defaultTabInfo, CountSBT, CountNFT })
        } catch (err) {
          console.error(err)
        }
      }
    })()
  }, [userId])

  return (
    <>
      <DetailTemplate
        pageInfo={pageInfo}
        detailTabInfo={detailTabInfo}
        userPermissionStatus={[userPermissionStatus, setUserPermissionStatus]}
      />
    </>
  )
}
