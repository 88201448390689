import React, { useEffect, useState } from 'react'
import DraggableGrid from '../../../components/template/Container/DraggableGrid/DraggableGrid'
import AddGridItemModal from '../../../components/organism/AddGridItemModal/AddGridItemModal'
import ConfirmDialog from '../../../components/molecules/ConfirmDialog/ConfirmDialog'
import FilterToggleButtonHeader from '../../../components/molecules/FilterToggleButtonHeader/FilterToggleButtonHeader'
import { toggleConfig } from './config'
import CardItem from '../../../components/molecules/CardItem/CardItem'
import { useToastContext } from '../../../context/ToastContext'
import { updateCompetition, getCompetitions } from '../../../api/competition'
import { getGroupIdByTokenId, getNftbyGroupId } from '../../../api/nft'
import { updateCompetitionOrder } from '../../../api/competition'
import { delateCompetition } from '../../../api/competition'

export default function Competition() {
  const [curation, setCuration] = useState()
  const [sortOrder, setSortOrder] = useState(toggleConfig)
  const [competitions, setCompetitions] = useState()
  const [addModalConfig, setAddModalConfig] = useState({
    isOpen: false,
    isModify: false,
  })
  const [isOpenConfirmDialog, setIsOpenConfirmDialog] = useState(false)
  const [updateCompetitions, setUpdateCompetition] = useState({
    _id: '',
    title: '',
    description: '',
    index: 0,
    isActive: true,
  })

  const { addToast } = useToastContext()

  const handleSortOrder = async value => {
    try {
      if (!value) return
      const updated = sortOrder.map(item =>
        item?.value === value
          ? { ...item, isSelected: true }
          : { ...item, isSelected: false }
      )

      await updateCompetitionOrder(value)
      setSortOrder(updated)
    } catch (e) {
      addToast({
        message: `${e.response.data.error || e.response.data.message || e}`,
        severity: 'error',
      })
    }
  }

  const resetUpdateCompetition = () => {
    setUpdateCompetition({ _id: '', description: '', index: 0, isActive: true })
  }

  const handleAddModal = (status, _id) => {
    if (status === 'open') {
      setAddModalConfig({
        isOpen: true,
        isModify: false,
      })
      setUpdateCompetition({
        ...updateCompetitions,
        index: competitions.length,
      })
    } else if (status === 'close') {
      setAddModalConfig({
        isOpen: false,
        isModify: false,
      })
      resetUpdateCompetition()
    } else if (status === 'edit') {
      setAddModalConfig({
        isOpen: true,
        isModify: true,
      })
      const selected = competitions.filter(item => item._id === _id)[0]
      setUpdateCompetition(selected)
    } else {
      console.error('알수없는 status입니다.')
    }
  }

  const handleChangeOrder = async newCompetitions => {
    try {
      const updated = newCompetitions.map((item, index) => ({ ...item, index }))
      await updateCompetition(updated)
      setCompetitions(await injectNftInfo(updated))
    } catch (e) {
      addToast({
        message: `${e.response.data.error || e.response.data.message || e}`,
        severity: 'error',
      })
    }
  }

  const handleClickDelete = _id => {
    const selected = competitions.filter(item => item._id === _id)[0]
    setUpdateCompetition(selected)
    setIsOpenConfirmDialog(true)
  }

  const handleDelete = async _id => {
    try {
      if (!_id) return

      const updated = competitions
        .filter(item => item._id !== _id)
        .map((item, index) => ({ ...item, index }))

      await delateCompetition(_id)
      setCompetitions(updated)
      closeDeleteConfirm()
    } catch (e) {
      addToast({
        message: `${e.response.data.error || e.response.data.message}`,
        severity: 'error',
      })
    }
  }

  const closeDeleteConfirm = () => {
    setIsOpenConfirmDialog(false)
    resetUpdateCompetition()
  }

  const handleUpdateCompetitions = (property, value) => {
    setUpdateCompetition(prev => ({ ...prev, [property]: value }))
  }

  const extractGroupIdFromUrl = url => {
    const split = url.split('/')
    const tokenId = split[split.length - 1]
    return getGroupIdByTokenId(tokenId)
  }

  const fetchUpdateCompetitionsInfo = async () => {
    try {
      if (!updateCompetitions._id) {
        setUpdateCompetition({
          _id: '',
          description: updateCompetitions.description,
        })
        return
      }
      const groupId = await extractGroupIdFromUrl(updateCompetitions._id)
      const {
        data: { cover_url, title },
      } = await getNftbyGroupId(groupId)
      handleUpdateCompetitions('cover_url', cover_url)
      handleUpdateCompetitions('title', title)
    } catch (e) {
      addToast({
        message: `${e.response.data.error || e.response.data.message}`,
        severity: 'error',
      })
    }
  }

  const handleSubmit = async isModify => {
    try {
      const { _id, description, index, isActive, title } = updateCompetitions
      if (!description || !/^[0-9]{4}$/.test(description)) {
        addToast({
          message: `대회 연도를 확인해주세요`,
          severity: 'error',
        })
        return
      }
      let updated
      if (isModify) {
        updated = competitions.map(item =>
          item._id === _id
            ? {
                _id,
                title,
                description,
                index,
                isActive,
              }
            : { ...item }
        )
      } else {
        updated = competitions.concat({
          _id: await extractGroupIdFromUrl(_id),
          title,
          description,
          index,
          isActive,
        })
      }
      const {
        data: { items },
      } = await updateCompetition(updated)
      setCompetitions(await injectNftInfo(ascendingArray(items, 'index')))

      handleAddModal('close')
    } catch (e) {
      addToast({
        message: `${e.response.data.error || e.response.data.message || e}`,
        severity: 'error',
      })
    }
  }

  const injectNftInfo = competitions => {
    return Promise.all(
      competitions.map(async item => {
        const {
          data: { cover_url },
        } = await getNftbyGroupId(item._id)
        return {
          ...item,
          cover_url,
        }
      })
    )
  }

  const ascendingArray = (array, standard) => {
    return array.sort((a, b) => a[standard] - b[standard])
  }

  useEffect(() => {
    try {
      async function fetchCurations() {
        const { data } = await getCompetitions()

        setCuration(data)
        setSortOrder(
          toggleConfig.map(item =>
            item?.value === data.sortOrder
              ? { ...item, isSelected: true }
              : { ...item, isSelected: false }
          )
        )

        const competitions = await injectNftInfo(
          ascendingArray(data.items, 'index')
        )
        setCompetitions(competitions)
      }
      fetchCurations()
    } catch (e) {
      console.error(e)
    }
  }, [])

  const Header = () => {
    return (
      <FilterToggleButtonHeader
        toggle={sortOrder}
        onChangeViewMethods={handleSortOrder}
        onClickAddButton={() => handleAddModal('open')}
        buttonLabel="대회 히스토리"
      />
    )
  }

  const GridItem = item => {
    return (
      <CardItem
        type="two"
        id={item._id}
        index={item.index + 1}
        caption={item.description}
        description={item.title}
        backgroundImage={item.cover_url}
        onClickDelete={() => handleClickDelete(item._id)}
        onClickModify={() => handleAddModal('edit', item._id)}
      />
    )
  }

  return (
    <>
      <DraggableGrid
        headerComponent={Header}
        itemConponent={GridItem}
        data={competitions || []}
        onChangeOrder={handleChangeOrder}
      />
      <AddGridItemModal
        type={curation?.type}
        title="대회 히스토리"
        open={addModalConfig.isOpen}
        isModify={addModalConfig.isModify}
        handleClose={() => handleAddModal('close')}
        handleSubmit={handleSubmit}
        item={updateCompetitions}
        handleChange={handleUpdateCompetitions}
        handleBlur={fetchUpdateCompetitionsInfo}
      />
      <ConfirmDialog
        open={isOpenConfirmDialog}
        title="대회 히스토리 삭제"
        content={`대회 히스토리 ${
          updateCompetitions.name || updateCompetitions.pageId
        } 를 삭제합니다. 삭제하시겠습니까?`}
        onClickCancel={closeDeleteConfirm}
        onClickConfirm={() => handleDelete(updateCompetitions._id)}
      />
    </>
  )
}
