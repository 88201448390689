import { lazy } from 'react'

import CommonLayout from '../components/template/CommonLayout/CommonLayout'
import routerRegex from './routerRegex'
import AuthGuard from './guard/AuthGuard'

/**
 * Loader
 */
// import { loader as UserDetailLoader } from '../routes/loaders/UserDetail'

/**
 * /user/*
 */
import List from '../pages/User/List/List'
import Players from '../pages/User/Players/Players'
import Pro from '../pages/User/Pro/Pro'
import UserDetail from '../pages/User/Detail/Detail'

/**
 * /nft/*
 */
import Sbt from '../pages/Nft/Sbt/Sbt'
import SbtSub from '../pages/Nft/Sbt/Sub/Sub'
import SbtRequest from '../pages/Nft/Sbt/Request/Request'
import Competition from '../pages/Nft/Competition/Competition'
/**
 * /banner
 */
import Banner from '../pages/Banner/Banner'
/**
 * error
 */
import NotFound from '../pages/NotFound'
import { Navigate } from 'react-router-dom'

/**
 * Lazy Loading
 */
// UserTab
const UserDefaultInfoTab = lazy(() =>
  import('../components/organism/UserDefaultInfoTab/UserDefaultInfoTab')
)
const UserSBTInfoTab = lazy(() =>
  import('../components/organism/UserSBTInfoTab/UserSBTInfoTab')
)

const protectedRoutes = {
  path: routerRegex.main.path,
  element: (
    <AuthGuard>
      <CommonLayout />
    </AuthGuard>
  ),
  errorElement: <NotFound />,
  children: [
    {
      path: routerRegex.main.path,
      element: <Navigate to={routerRegex.user.list.path} replace />,
    },
    { path: routerRegex.user.list.path, element: <List /> },
    {
      path: routerRegex.user.detail.path,
      element: <UserDetail />,
      // loader: UserDetailLoader,
      children: [
        {
          path: '',
          element: <UserDefaultInfoTab />,
        },
        {
          path: routerRegex.user.detail.sbtList.path,
          element: <UserSBTInfoTab />,
          loader: async () => {
            return true
          },
        },
      ],
    },
    { path: routerRegex.user.players.path, element: <Players /> },
    { path: routerRegex.user.pro.path, element: <Pro /> },
    { path: routerRegex.nft.sbt.path, element: <Sbt /> },
    { path: routerRegex.nft.sbt.sub.path, element: <SbtSub /> },
    { path: routerRegex.nft.sbt.request.path, element: <SbtRequest /> },
    { path: routerRegex.nft.competition.path, element: <Competition /> },
    { path: routerRegex.banner.path, element: <Banner /> },
  ],
}

export default protectedRoutes
