import { Box, Button, Modal, TextField, Typography } from '@mui/material'
import { Stack } from '@mui/system'
import React from 'react'
import { uploadCurationBgImage } from '../../../api/curation'
import CardItem from '../../molecules/CardItem/CardItem'
import UploadArea from '../../molecules/UploadArea/UploadArea'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%',
  maxWidth: 789,
  bgcolor: 'background.paper',
  color: 'text.primary',
  boxShadow: 24,
  p: 4,
}

export default function AddGridItemModal({
  type,
  title,
  open,
  handleClose,
  handleSubmit,
  isModify,
  item,
  handleChange,
  handleBlur,
  caption,
}) {
  const handleUploadImage = async (id, file) => {
    let fd = new FormData()
    fd.append('file', file)
    const {
      data: {
        data: { url },
      },
    } = await uploadCurationBgImage(fd)
    handleChange(id, url)
  }
  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={style}>
        <Stack gap={2}>
          <Typography variant="h5">{title || '타이틀 영역'}</Typography>
          <Stack direction={'row'}>
            <Stack flex="1" gap={3}>
              {type === 'users' && (
                <TextField
                  label="페이지 주소 입력"
                  variant="outlined"
                  helperText="https:// 로 시작하는 전체주소를 입력해 주세요."
                  value={
                    isModify
                      ? `${process.env.REACT_APP_CCCV_URL}/${item._id}`
                      : item._id
                  }
                  disabled={isModify}
                  onChange={e => handleChange('_id', e.target.value)}
                  onBlur={handleBlur}
                />
              )}
              {type === 'nft' && (
                <TextField
                  label="nft 주소 입력"
                  variant="outlined"
                  helperText="https:// 로 시작하는 전체주소를 입력해 주세요."
                  value={
                    isModify
                      ? `${process.env.REACT_APP_CCCV_URL}/nft/${item._id}`
                      : item._id
                  }
                  disabled={isModify}
                  onChange={e => handleChange('_id', e.target.value)}
                  onBlur={handleBlur}
                />
              )}
              {type === 'users' && (
                <>
                  <UploadArea
                    id="customImage"
                    label="카드 배경이미지 등록"
                    width={1024}
                    height={1024}
                    placeholder="파일 선택"
                    onChangeFile={handleUploadImage}
                    url={item.customImage}
                  />
                  <TextField
                    label={caption}
                    variant="outlined"
                    helperText={`${caption}을 입력해주세요.`}
                    value={item.description}
                    onChange={e => handleChange('description', e.target.value)}
                  />
                </>
              )}
              {type === 'nft' && (
                <>
                  <TextField
                    label="대회 이름"
                    variant="outlined"
                    helperText="대회 이름을 입력해주세요"
                    defaultValue={item.title}
                    value={item.title}
                    onChange={e => handleChange('title', e.target.value)}
                  />
                  <TextField
                    label="대회 연도"
                    variant="outlined"
                    helperText="대회 연도를 4자리로 입력해주세요(ex. 2023)"
                    value={item.description}
                    onChange={e => handleChange('description', e.target.value)}
                  />
                </>
              )}
            </Stack>
            <Box flex="1" px={5}>
              {type === 'users' && (
                <CardItem
                  index={item?.index + 1}
                  isActive={item?.isActive}
                  caption={caption}
                  title={item?.name || item?.pageId}
                  avatar={item?.profileImgUrl}
                  backgroundImage={item?.customImage}
                  description={item?.description}
                />
              )}
              {type === 'nft' && (
                <CardItem
                  type="two"
                  id={item._id}
                  index={item.index + 1}
                  caption={item.description}
                  description={item.title}
                  backgroundImage={item.cover_url}
                />
              )}
            </Box>
          </Stack>
          <Stack direction={'row'} justifyContent="flex-end" gap={2}>
            <Button variant="outlined" color="error" onClick={handleClose}>
              취소
            </Button>
            <Button variant="outlined" onClick={() => handleSubmit(isModify)}>
              {isModify ? '수정' : '등록'}
            </Button>
          </Stack>
        </Stack>
      </Box>
    </Modal>
  )
}
