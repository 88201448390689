import { Icon, ListItemButton, ListItemIcon, ListItemText } from '@mui/material'
import React from 'react'

export default function ListItem({
  handleClick = () => {},
  text = 'List Item',
  iconName = '',
  ...rest
}) {
  /**
   * Icon 영역 렌더링
   */
  const renderIcon = () => {
    if (iconName === '') return null

    return (
      <ListItemIcon>
        <Icon>{iconName}</Icon>
      </ListItemIcon>
    )
  }

  return (
    <ListItemButton onClick={handleClick} {...rest}>
      {renderIcon()}
      <ListItemText primary={text} />
    </ListItemButton>
  )
}
