import React, { useEffect, useState } from 'react'
import Grid from '../../../components/template/Container/Grid/Grid'
import SbtCard from '../../../components/organism/SbtCard/SbtCard'
import { useNavigate } from 'react-router-dom'
import { getSbt, updateSbtStatus } from '../../../api/sbt'
import { useToastContext } from '../../../context/ToastContext'

export default function Sbt() {
  const [sbt, setSbt] = useState()
  const natigate = useNavigate()
  const [filters, setFilters] = useState([
    { label: '전체', value: 'total', isSelected: true },
    { label: '활성', value: 'active', isSelected: false },
    { label: '비활성', value: 'unactive', isSelected: false },
  ])

  const { addToast } = useToastContext()

  useEffect(() => {
    try {
      async function fetchSbt() {
        const { data } = await getSbt()
        setSbt(data)
      }
      fetchSbt()
    } catch (e) {
      console.error(e)
    }
  }, [])

  const onChangeFilter = value => {
    if (!value) return
    const updated = filters.map(item =>
      item?.value === value
        ? { ...item, isSelected: true }
        : { ...item, isSelected: false }
    )
    setFilters(updated)
  }

  const onClickSbtApply = () => {
    window.open('https://doda.app/embed/Yuc8r169CP')
  }

  const onClickToggle = async (id, checked) => {
    try {
      const updated = sbt.map(item =>
        item._id === id ? { ...item, isShow: checked } : { ...item }
      )
      await updateSbtStatus(id, checked)
      setSbt(updated)
    } catch (e) {
      addToast({
        message: `${e.response.data.error || e.response.data.message || e}`,
        severity: 'error',
      })
    }
  }

  const onClickCard = id => {
    natigate(`/nft/sbt/${id}/sub`)
  }

  return (
    <>
      <Grid
        data={sbt}
        filters={filters}
        onChangeFilter={onChangeFilter}
        onClickButton={onClickSbtApply}
        buttonLabel="SBT 신청하기"
        GridItemComponent={SbtCard}
        onClickToggle={onClickToggle}
        onClickCard={onClickCard}
      />
    </>
  )
}
