import { Stack, TextField } from '@mui/material'
import React from 'react'
import ModalFrame from '../../molecules/ModalFrame/ModalFrame'

export default function UserInfoModal({ userInfo, handleClose }) {
  return (
    <ModalFrame
      open={Boolean(userInfo)}
      handleClose={handleClose}
      handleConfirm={handleClose}
      confirmLabel="확인"
      title="유저정보"
    >
      <Stack gap={2} sx={{ width: '100vw', maxWidth: '480px' }} p={2}>
        <TextField
          disabled
          label="페이지 주소"
          defaultValue={userInfo?.pageId}
        />
        <TextField disabled label="이메일" defaultValue={userInfo?.email} />
        <TextField
          disabled
          label="이름"
          defaultValue={userInfo?.name}
          helperText="본인인증 받은 실명입니다."
        />
        <TextField
          disabled
          label="전화번호"
          defaultValue={userInfo?.phoneNumber}
          helperText="본인인증 받은 휴대전화 번호입니다."
        />
        <TextField
          disabled
          label="전화번호"
          defaultValue={userInfo?.birthday}
          helperText="본인인증 받은 생년월일 입니다."
        />
      </Stack>
    </ModalFrame>
  )
}
