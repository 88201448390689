export const filtersConfig = [
  {
    label: '전체',
    value: 'total',
    isSelected: true,
  },
  {
    label: '활성화',
    value: 'active',
    isSelected: false,
  },
  {
    label: '비활성화',
    value: 'unactive',
    isSelected: false,
  },
]

export const toggleConfig = [
  {
    label: '리스트순',
    value: 'index',
    isSelected: true,
  },
  { label: '랜덤순', value: 'random', isSelected: false },
]
