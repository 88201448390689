import {
  Stack,
  Container,
  Button,
  Typography,
  ToggleButton,
  ToggleButtonGroup,
  Grid as MuiGrid,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import Searchbar from '../../../molecules/Searchbar/Searchbar'
import Box16by9 from '../../Util/Box16by9'

export default function Grid({
  data,
  filters,
  onChangeFilter,
  buttonLabel,
  GridItemComponent,
  onClickButton,
  onClickToggle,
  onClickCard,
}) {
  const [selectedFilter, setSelectedFilter] = React.useState('')
  const [keyword, setKeyword] = useState('')
  const handleChangeFilter = (event, newValue) => {
    onChangeFilter(newValue)
  }

  const handleKeyword = e => {
    const { value } = e.target
    setKeyword(value)
  }

  useEffect(() => {
    const { value } = filters.filter(item => item.isSelected)[0]
    setSelectedFilter(value)
  }, [filters])

  return (
    <Container>
      <Stack py={5} gap={5}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          gap={2}
        >
          <Searchbar
            hasFilter={false}
            keyword={keyword}
            onChangeKeyword={handleKeyword}
          />
          <Button
            variant="outlined"
            size="large"
            onClick={onClickButton}
            sx={{ flexShrink: 0 }}
          >
            {buttonLabel || 'Large'}
          </Button>
        </Stack>
        <Stack gap={3}>
          <Stack gap={1}>
            <Typography variant="h5" sx={{ color: 'text.primary' }}>
              Filter
            </Typography>
            <ToggleButtonGroup
              color="primary"
              value={selectedFilter}
              exclusive
              onChange={handleChangeFilter}
              aria-label="filter"
            >
              {filters.map((item, index) => (
                <ToggleButton key={index} value={item.value}>
                  {item.label}
                </ToggleButton>
              ))}
            </ToggleButtonGroup>
          </Stack>
          <MuiGrid container spacing={2}>
            {data &&
              selectedFilter === 'total' &&
              data
                .filter(item => item.name.includes(keyword))
                .map(item => (
                  <MuiGrid xs={4} item key={item._id}>
                    {GridItemComponent ? (
                      <GridItemComponent
                        item={item}
                        onClickToggle={onClickToggle}
                        onClickCard={onClickCard}
                      />
                    ) : (
                      <Box16by9 />
                    )}
                  </MuiGrid>
                ))}
            {data &&
              selectedFilter !== 'total' &&
              data
                .filter(item =>
                  selectedFilter === 'active' ? item.isShow : !item.isShow
                )
                .filter(item => item.name.includes(keyword))
                .map(item => (
                  <MuiGrid xs={4} item key={item._id}>
                    {GridItemComponent ? (
                      <GridItemComponent
                        item={item}
                        onClickToggle={onClickToggle}
                        onClickCard={onClickCard}
                      />
                    ) : (
                      <Box16by9 />
                    )}
                  </MuiGrid>
                ))}
          </MuiGrid>
        </Stack>
      </Stack>
    </Container>
  )
}
