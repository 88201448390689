import { forwardRef } from 'react'
import { useNavigate, useLocation, matchPath, NavLink } from 'react-router-dom'
import { Box, List, Stack } from '@mui/material'

import Ci from '../../molecules/Ci/Ci'
import ActionableAvatar from '../../molecules/ActionableAvatar/ActionableAvatar'
import NestedList from '../../molecules/NesatedList/NestedList'
import ListItem from '../../molecules/ListItem/ListItem'
import getSidebarConfig from './SidebarConfig'
import { useAuthContext } from '../../../context/AuthContext'

const CustomNavLink = forwardRef((props, ref) => {
  return (
    <NavLink
      ref={ref}
      {...props}
      className={({ isActive }) =>
        isActive ? props.className + ' Mui-selected' : props.className
      }
      end
    />
  )
})

export default function Sidebar() {
  const navigate = useNavigate()
  const { user, logout } = useAuthContext()
  const { pathname: currentPathName } = useLocation()

  const handleLogout = () => {
    logout(navigate('/login'))
  }

  return (
    <Box>
      <Stack sx={{ p: 5 }} gap={5}>
        <Ci />
        <ActionableAvatar
          buttonLabel="로그아웃"
          action={handleLogout}
          name={user?.name || user?.pageId}
          src={user?.profileImgUrl}
        />
      </Stack>
      <List>
        {getSidebarConfig.map(_sideMenuItem => {
          const isNestedMenu = _sideMenuItem.nestedList

          return isNestedMenu ? (
            <NestedList
              key={_sideMenuItem.text}
              text={_sideMenuItem.text}
              iconName={_sideMenuItem.iconName}
              nestedlist={_sideMenuItem.nestedList}
              wrapperProps={{
                component: 'li',
              }}
              nestedlistitemprops={{
                component: CustomNavLink,
                sx: {
                  pl: 9,
                },
              }}
              selected={
                matchPath(_sideMenuItem.pathRegex, currentPathName) === null
                  ? false
                  : true
              }
            />
          ) : (
            <ListItem
              key={_sideMenuItem.text}
              text={_sideMenuItem.text}
              to={_sideMenuItem.path}
              iconName={_sideMenuItem.iconName}
              component={CustomNavLink}
              sx={{ color: 'text.primary' }}
            />
          )
        })}
      </List>
    </Box>
  )
}
