import { Box, Typography } from '@mui/material'
import { Stack } from '@mui/system'
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router'
import loginBanner from '../../assets/png/image/login-banner.png'
import Logo from '../../components/atoms/Logo/Logo'
import { afterRedirectByGoogle, signInWithGoogle } from '../../service/auth'
import LoadingButton from '@mui/lab/LoadingButton'
import SnsLogo from '../../components/atoms/SnsLogo/SnsLogo'

export default function Login() {
  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState('')
  const location = useLocation()
  const navigate = useNavigate()
  useEffect(() => {
    async function googleProcess() {
      const { isAdmin, isAfter } = await afterRedirectByGoogle(location)
      if (!isAfter) return
      setLoading(true)
      if (isAdmin) {
        navigate('/user/list')
      } else {
        setMessage('관리자 계정이 아닙니다.')
        setLoading(false)
        navigate('/login')
      }
    }
    googleProcess()
  }, [location, navigate])

  const handleClick = () => {
    setLoading(true)
    signInWithGoogle()
  }

  return (
    <Stack
      direction="row"
      sx={{ height: '100%', backgroundColor: 'background.paper' }}
    >
      <Box
        sx={{
          background: `center / cover no-repeat url(${loginBanner})`,
          flexBasis: 'calc((880 / 1440) * 100%)',
        }}
      ></Box>
      <Stack
        alignItems="center"
        justifyContent="center"
        sx={{ flexBasis: 'calc((880 / 1440) * 100%)' }}
        gap={8}
      >
        <Logo size="xxl" fill="#ffffff" />
        <Stack gap={1}>
          <Typography variant="h4" sx={{ color: 'text.primary' }}>
            Sign in with
          </Typography>
          <LoadingButton
            startIcon={<SnsLogo service="google" />}
            variant="outlined"
            size="large"
            loading={loading}
            onClick={handleClick}
          >
            GOOGLE
          </LoadingButton>
          {message && (
            <Typography variant="caption" color="error">
              {message}
            </Typography>
          )}
        </Stack>
      </Stack>
    </Stack>
  )
}
