import client from './client'

/**
 * 회원 리스트 조회
 */
export const getUsers = ({ keyword, roles, searchUsersType, limit, page }) => {
  return client.get(`admins-customers/nabba/users`, {
    params: {
      keyword,
      roles,
      searchUsersType,
      limit,
      page,
    },
  })
}

/**
 * 나바 SBT 회수
 */
export const deleteNabbaSBT = ({ pageBadgeId }) => {
  return client.delete(`admins-customers/nabba/sbt/${pageBadgeId}`, {
    data: {
      pageBadgeId,
    },
  })
}

/**
 * 나바 SBT 부여
 */
export const postNabbaSBT = ({ userId, badgeId, subItemId }) => {
  return client.post(`admins-customers/nabba/sbt`, {
    user_id: userId,
    badge_id: badgeId,
    subItem_id: subItemId,
  })
}

/**
 * 나바 SBT 조회
 */
export const getNabbaAllSBT = () => {
  return client.get(`admins-customers/nabba/sbt`)
}

/**
 * 나바 SUB-SBT 조회
 */
export const getNabbaSubSBT = ({ roles, badgeName }) => {
  return client.get(`admins-customers/nabba/sub-badges`, {
    params: {
      roles,
      badgeName,
    },
  })
}
export const getUserByPageId = pageId =>
  client.get('/pages/getPage', {
    params: { pageId },
  })

export const getVerifictionsByPageId = pageId =>
  client.get('/verifications/pageVerifications', {
    params: {
      pageId,
    },
  })
