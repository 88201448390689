import { Box, Typography } from '@mui/material'
import { Stack } from '@mui/system'
import React, { useState } from 'react'

export default function UploadArea({
  id,
  width,
  height,
  label,
  helperText,
  placeholder,
  onChangeFile,
  url,
}) {
  const [preview, setPreview] = useState(url)
  const onChange = e => {
    const { files } = e.target
    let fileReader = new FileReader()
    fileReader.onload = e => {
      setPreview(e.target.result)
    }
    fileReader.readAsDataURL(files[0])
    if (onChangeFile && id) onChangeFile(id, files[0])
  }

  return (
    <Stack gap={0.5}>
      <Typography>{label}</Typography>
      <Box
        component="label"
        sx={{
          position: 'relative',
          backgroundColor: 'rgba(255, 255, 255, 0.13)',
          width: '100%',
          paddingTop: `${(height / width) * 100}%`,
          borderRadius: 1,
          cursor: 'pointer',
        }}
      >
        {preview ? (
          <img
            src={preview}
            alt="미리보기"
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
            }}
          />
        ) : (
          <Typography
            variant="h5"
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
            }}
          >
            {placeholder}
          </Typography>
        )}
        <input
          hidden
          accept="image/*"
          multiple
          type="file"
          onChange={onChange}
        />
      </Box>
      <Typography variant="caption">{helperText}</Typography>
    </Stack>
  )
}
