import {
  Button,
  FormControl,
  Icon,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material'
import React, { useEffect, useState } from 'react'

export default function FilterToggleButtonHeader({
  selectLabel = 'Sort by',
  filters,
  onChangeFilter,
  toggle,
  onChangeViewMethods,
  buttonLabel = '버튼',
  onClickAddButton,
}) {
  const [selectedFilter, setSelectedFilter] = useState('')
  const [selectedToggle, setSelectedToggle] = useState('')

  const handleChangeFilter = event => {
    const {
      target: { value },
    } = event
    onChangeFilter(value)
  }

  const handleChangeToggle = (event, newValue) => {
    onChangeViewMethods(newValue)
  }

  useEffect(() => {
    if (filters) {
      const selectedFilter = filters.filter(item => item.isSelected)[0]?.value
      setSelectedFilter(selectedFilter)
    }

    const selectedToggle = toggle.filter(item => item.isSelected)[0]?.value
    setSelectedToggle(selectedToggle)
  }, [filters, toggle])

  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center">
      <Stack direction="row" alignItems="center" gap={2}>
        {filters && (
          <FormControl sx={{ minWidth: 220 }}>
            <InputLabel id="grid-header-label">{selectLabel}</InputLabel>
            <Select
              labelId="grid-header-label"
              id="grid-template-header"
              value={selectedFilter}
              label="Age"
              onChange={handleChangeFilter}
              size="small"
            >
              {filters.map((item, index) => (
                <MenuItem value={item.value} key={index}>
                  {item.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        <ToggleButtonGroup
          color="primary"
          value={selectedToggle}
          exclusive
          onChange={handleChangeToggle}
          aria-label="Platform"
          size="small"
        >
          {toggle.map((item, index) => (
            <ToggleButton value={item.value} key={index}>
              {item.label}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
      </Stack>
      <Button
        variant="outlined"
        size="large"
        startIcon={<Icon>add_to_photos_filled</Icon>}
        onClick={onClickAddButton}
      >
        {buttonLabel}
      </Button>
    </Stack>
  )
}
