import client from './client'

export const getPage = ({ pageId }) => {
  return client.get(`pages/getPage`, {
    params: {
      pageId,
    },
  })
}

/**
 * 회원 상세 기본정보탭 조회
 */
export const getBasicInfo = ({ userId }) => {
  return client.get(`admins-customers/nabba/users/${userId}/basic-info`)
}

/**
 * Count SBT
 */
export const getSBTCount = ({ userId }) => {
  return client.get(`admins-customers/nabba/sbt/count`, {
    params: {
      userId,
    },
  })
}

/**
 * Count NFT
 */
export const getNFTCount = ({ userId }) => {
  return client.get(`admins-customers/nabba/nft/count`, {
    params: {
      userId,
    },
  })
}

/**
 * 유저가 소유한 Nabba SBT 목록 조회
 */
export const getSbtList = ({ pageId, role }) => {
  return client.get(`admins-customers/nabba/sbt/${pageId}`, {
    params: {
      pageId,
      role,
    },
  })
}
