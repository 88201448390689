export const headers = [
  {
    label: '유저정보',
    dataKey: 'userInfo',
  },
  {
    label: '페이지',
    dataKey: 'pageId',
  },
  {
    label: '부여배지 이름',
    dataKey: 'name',
  },
  {
    label: '메모',
    dataKey: 'memo',
  },
  {
    label: '반려',
    dataKey: 'refuse',
  },
  {
    label: '요청일',
    dataKey: 'createdAt',
  },
  {
    label: '활성화',
    dataKey: 'isAdminShow',
  },
]

const sample = [
  [
    'rooney',
    '2022 AOC Bodybuilding ',
    '관리자 승인 - 홍길동',
    true,
    '2022/12/31',
    true,
  ],
  [
    'rooney',
    '2022 AOC MISS FIGURE ',
    '관리자 승인 - 홍길동',
    true,
    '2022/12/31',
    true,
  ],
  [
    'rooney',
    '2022 AOC BIKINI MODEL',
    '관리자 승인 - 홍길동',
    false,
    '2022/12/31',
    false,
  ],
  [
    'rooney',
    '2022 AOC BERMUDA MODEL',
    '관리자 승인 - 홍길동',
    false,
    '2022/12/31',
    true,
  ],
]

function createData(
  id,
  pageId,
  badgeName,
  memo,
  isRefused,
  requestedAt,
  isActive
) {
  return {
    id,
    pageId,
    badgeName,
    memo,
    isRefused,
    requestedAt,
    isActive,
  }
}

export const sampleData = Array.from({ length: 100 }, (_, index) => {
  const randomSelection = sample[Math.floor(Math.random() * sample.length)]
  return createData(index, ...randomSelection)
})
