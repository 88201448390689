export const categoryItems = [
  { label: '이름', value: 'name' },
  { label: '이메일', value: 'email' },
  { label: '페이지 주소', value: 'pageId' },
  { label: '휴대전화', value: 'phoneNumber' },
]

export const headers = [
  {
    label: '소셜',
    dataKey: 'snsType',
  },
  {
    label: '이름',
    dataKey: 'name',
  },
  {
    label: '이메일',
    dataKey: 'email',
  },
  {
    label: 'CCCV',
    dataKey: 'cccv',
  },
  {
    label: 'CCCV.NFT',
    dataKey: 'cccv_nft',
  },
]

const sample = [
  ['google', '김나바', 'kimnaba@google.com', 'rooney'],
  ['google', 'Josepjus Adolphus', 'ab3@naver.com', 'abc3'],
  [
    'kakao',
    ' Leonidus Wolsicanicus',
    'LeonidusWolsicanicus@naver.com',
    'Leonidus',
  ],
  ['kakao', '홍길동', 'aasdfasdf123123sdf3@naver.com', 'aasdfasdf123123sdf3'],
  ['naver', 'Cheolhun Bae', 'aasdsdff3@kakao.com', 'aasdsdff3'],
]

function createData(id, snsType, name, email, pageId) {
  return { id, snsType, name, email, pageId }
}

export const sampleData = Array.from({ length: 100 }, (_, index) => {
  const randomSelection = sample[Math.floor(Math.random() * sample.length)]
  return createData(index, ...randomSelection)
})
