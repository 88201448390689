import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import InputAdornment from '@mui/material/InputAdornment'
import { Box, Stack, TextField, Icon } from '@mui/material'

export default function Searchbar({
  menus,
  selectedCategory,
  keyword,
  onChangeKeyword,
  onSubmit,
}) {
  const onKeyPress = event => {
    if (event.key === 'Enter') onSubmit()
  }

  return (
    <Box
      sx={{
        width: '100%',
        // maxWidth: '800px',
      }}
    >
      <Stack direction="row" gap={1}>
        {menus?.length > 0 && (
          <FormControl
            sx={{
              flex: '0 0 152px',
            }}
          >
            <InputLabel id="SearchbarSelectLabel">키워드</InputLabel>
            <Select
              labelId="SearchbarSelectLabel"
              id="demo-simple-select"
              value={selectedCategory && selectedCategory[0]}
              label="Keyword"
              onChange={e => {
                selectedCategory && selectedCategory[1](e.target.value)
              }}
            >
              {menus?.map((item, i) => {
                return (
                  <MenuItem
                    value={item.value}
                    key={i}
                    selected={
                      item.value === selectedCategory && selectedCategory[0]
                    }
                  >
                    {item.label}
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>
        )}
        <TextField
          sx={{
            flex: '1 1 auto',
          }}
          label="검색어를 입력해주세요."
          value={keyword}
          onChange={onChangeKeyword}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Icon>search_filled</Icon>
              </InputAdornment>
            ),
          }}
          onKeyPress={onKeyPress}
        />
      </Stack>
    </Box>
  )
}
