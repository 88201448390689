import { Card, Skeleton, Stack, Switch, Typography } from '@mui/material'
import React from 'react'

export default function SbtCard({ item, onClickToggle, onClickCard }) {
  return (
    <Card
      onClick={() => onClickCard(item._id)}
      sx={{
        width: '100%',
        paddingTop: '56.25%',
        position: 'relative',
        cursor: 'pointer',
      }}
    >
      <Stack
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
        }}
        p={1}
        gap={1}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="h6">
            {item.name || (
              <Skeleton variant="rectangular" width={150} height={32} />
            )}
          </Typography>
          <Switch
            size="small"
            checked={item.isShow}
            onClick={event => event.stopPropagation()}
            onChange={event => onClickToggle(item._id, event.target.checked)}
          />
        </Stack>
        <Stack direction="row" gap={1}>
          {item.imageUrl ? (
            <img src={item.imageUrl} width="120px" alt={item.name} />
          ) : (
            <Skeleton
              sx={{ flexShrink: 0 }}
              variant="rectangular"
              width={120}
              height={120}
            />
          )}
          <Stack width="100%">
            {item.pageBadgeNum ? (
              <Stack direction="row" gap={1}>
                <Typography>발행건</Typography>
                <Typography>{`${item.pageBadgeNum}건`}</Typography>
              </Stack>
            ) : (
              <Skeleton variant="text" sx={{ fontSize: '1.4rem' }} />
            )}
            {true ? (
              <Stack direction="row" gap={1}>
                <Typography>활성기간</Typography>
                <Typography>{true ? '유효함' : '유효하지않음'}</Typography>
              </Stack>
            ) : (
              <Skeleton variant="text" sx={{ fontSize: '1.4rem' }} />
            )}
            {/* {item.publisher ? (
              <Stack direction="row" gap={1}>
                <Typography>발행자</Typography>
                <Typography>{item.publisher}</Typography>
              </Stack>
            ) : (
              <Skeleton variant="text" sx={{ fontSize: '1.4rem' }} />
            )} */}
            {item.description ? (
              <Stack direction="row" gap={1}>
                <Typography sx={{ flexShrink: 0 }}>설명</Typography>
                <Typography>{item.description}</Typography>
              </Stack>
            ) : (
              <Skeleton variant="text" sx={{ fontSize: '1.4rem' }} />
            )}
          </Stack>
        </Stack>
      </Stack>
    </Card>
  )
}
