import React, { useEffect, useState } from 'react'
import DraggableGrid from '../../components/template/Container/DraggableGrid/DraggableGrid'
import ConfirmDialog from '../../components/molecules/ConfirmDialog/ConfirmDialog'
import FilterToggleButtonHeader from '../../components/molecules/FilterToggleButtonHeader/FilterToggleButtonHeader'
import { filtersConfig, toggleConfig } from './config'
import BannerItem from '../../components/organism/BannerItem/BannerItem'
import AddBannerModal from '../../components/organism/AddBannerModal/AddBannerModal'
import {
  addBanner,
  deleteBanner,
  editBanner,
  getBanners,
  getBannerSortOrder,
  updateBannerSortOrder,
  updateBannersSort,
  updateBannerStatus,
} from '../../api/banner'

export default function Banner() {
  const [filters, setFilters] = useState(filtersConfig)
  const [sortOrder, setSortOrder] = useState()
  const [banner, setBanner] = useState()
  const [addModalConfig, setAddModalConfig] = useState({
    isOpen: false,
    isModify: false,
  })
  const [isOpenConfirmDialog, setIsOpenConfirmDialog] = useState(false)
  const [updateBanner, setUpdateBanner] = useState({
    bannerId: '',
    imageUrlPc: '',
    imageUrlMobile: '',
    linkUrl: '',
    sortNum: 0,
    status: true,
    closingDate: null,
  })

  const handleFilter = async value => {
    if (!value) return
    const updated = filters.map(item =>
      item?.value === value
        ? { ...item, isSelected: true }
        : { ...item, isSelected: false }
    )

    setFilters(updated)
  }

  const handleSortOrder = async value => {
    if (!value) return
    const updated = sortOrder.map(item =>
      item?.value === value
        ? { ...item, isSelected: true }
        : { ...item, isSelected: false }
    )
    await updateBannerSortOrder(value === 'random')
    setSortOrder(updated)
  }

  const resetUpdateBanner = () => {
    setUpdateBanner({
      bannerId: '',
      imageUrlPc: '',
      imageUrlMobile: '',
      linkUrl: '',
      sortNum: 0,
      status: true,
      closingDate: null,
    })
  }

  const handleAddModal = (status, _id) => {
    if (status === 'open') {
      setAddModalConfig({
        isOpen: true,
        isModify: false,
      })
      setUpdateBanner({ ...updateBanner, sortNum: banner.length })
    } else if (status === 'close') {
      setAddModalConfig({
        isOpen: false,
        isModify: false,
      })
      resetUpdateBanner()
    } else if (status === 'edit') {
      setAddModalConfig({
        isOpen: true,
        isModify: true,
      })
      const selected = banner.filter(item => item.bannerId === _id)[0]
      setUpdateBanner(selected)
    } else {
      console.error('알수없는 status입니다.')
    }
  }

  const handleChangeOrder = async newBanners => {
    const updated = newBanners.map((item, index) => ({
      ...item,
      sortNum: index,
    }))
    const payload = updated.map(({ bannerId, sortNum }) => ({
      bannerId,
      sortNum,
    }))

    await updateBannersSort(payload)
    setBanner(updated)
  }

  const handleToggle = async (checked, id) => {
    if (!id) return
    const updated = banner.map(item =>
      item.bannerId === id ? { ...item, status: checked } : { ...item }
    )
    await updateBannerStatus(id, checked)
    setBanner(updated)
  }

  const handleClickDelete = _id => {
    const selected = banner.filter(item => item.bannerId === _id)[0]
    setUpdateBanner(selected)
    setIsOpenConfirmDialog(true)
  }

  const handleDelete = async _id => {
    try {
      if (!_id) return

      const updated = banner.filter(item => item.bannerId !== _id)

      await deleteBanner(_id)
      await handleChangeOrder(updated)

      closeDeleteConfirm()
    } catch (e) {
      console.log(e)
    }
  }

  const closeDeleteConfirm = () => {
    setIsOpenConfirmDialog(false)
    resetUpdateBanner()
  }

  const handleUpdateBanner = (property, value) => {
    setUpdateBanner(prev => ({ ...prev, [property]: value }))
  }

  const handleSubmit = async isModify => {
    if (isModify) {
      await editBanner(updateBanner)
      const updated = banner.map(item =>
        item.bannerId === updateBanner.bannerId
          ? { ...updateBanner }
          : { ...item }
      )
      setBanner(updated)
    } else {
      const updated = { ...updateBanner }
      delete updated.bannerId
      const {
        data: { items },
      } = await addBanner(updated)
      setBanner(items)
    }

    handleAddModal('close')
  }

  const ascendingArray = (array, standard) => {
    return array.sort((a, b) => a[standard] - b[standard])
  }

  useEffect(() => {
    async function fetchBanner() {
      const { data } = await getBanners()
      const {
        data: { isRandom },
      } = await getBannerSortOrder()
      if (isRandom) {
        setSortOrder(
          toggleConfig.map(item =>
            item?.value === 'random'
              ? { ...item, isSelected: true }
              : { ...item, isSelected: false }
          )
        )
      } else {
        setSortOrder(
          toggleConfig.map(item =>
            item?.value === 'index'
              ? { ...item, isSelected: true }
              : { ...item, isSelected: false }
          )
        )
      }

      setBanner(ascendingArray(data, 'sortNum'))
    }
    fetchBanner()
  }, [])

  const Header = () => {
    return (
      <FilterToggleButtonHeader
        filters={filters}
        onChangeFilter={handleFilter}
        toggle={sortOrder || toggleConfig}
        onChangeViewMethods={handleSortOrder}
        onClickAddButton={() => handleAddModal('open')}
        buttonLabel="배너 추가"
      />
    )
  }

  const GridItem = item => {
    return (
      <BannerItem
        id={item.bannerId}
        index={item.sortNum + 1}
        isActive={item.status}
        pcImage={item.imageUrlPc}
        closingDate={item.closingDate}
        onChangeToggle={handleToggle}
        onClickDelete={handleClickDelete}
        onClickModify={handleAddModal}
      />
    )
  }

  return (
    <>
      <DraggableGrid
        filters={filters}
        headerComponent={Header}
        itemConponent={GridItem}
        data={banner || []}
        onChangeOrder={handleChangeOrder}
        isBanner={true}
      />
      <AddBannerModal
        title="배너 추가"
        open={addModalConfig.isOpen}
        isModify={addModalConfig.isModify}
        handleClose={() => handleAddModal('close')}
        handleSubmit={handleSubmit}
        onChange={handleUpdateBanner}
        item={updateBanner}
      />
      <ConfirmDialog
        open={isOpenConfirmDialog}
        title="배너 삭제"
        content={`${updateBanner.bannerName} 를 삭제합니다. 삭제하시겠습니까?`}
        onClickCancel={closeDeleteConfirm}
        onClickConfirm={() => handleDelete(updateBanner.bannerId)}
      />
    </>
  )
}
