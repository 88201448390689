import {
  Avatar,
  Box,
  Button,
  Card,
  Chip,
  Skeleton,
  Stack,
  Switch,
  Typography,
} from '@mui/material'
import React from 'react'

export default function CardItem({
  type = 'default',
  index,
  title,
  description,
  caption,
  isActive,
  avatar,
  id,
  backgroundImage,
  onChangeToggle,
  onClickDelete = () => {},
  onClickModify = () => {},
}) {
  const handleChange = (event, id) => {
    if (!onChangeToggle) return
    const {
      target: { checked },
    } = event
    onChangeToggle(checked, id)
  }

  return (
    <Card
      sx={{
        width: '100%',
        paddingTop: '177.77%',
        position: 'relative',
        overflow: 'visible',
      }}
    >
      <Stack
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
        }}
      >
        <Box sx={{ flex: 2 }}>
          <Chip
            label={index || 'n'}
            color="primary"
            sx={{
              position: 'absolute',
              transform: 'translate(-25%, -25%)',
            }}
          />
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            px={2}
            height="100%"
          >
            {type === 'default' ? (
              <Switch
                onChange={event => handleChange(event, id)}
                checked={isActive}
              />
            ) : (
              <div></div>
            )}

            <Stack direction="row">
              <Button
                color="error"
                size="large"
                onClick={() => onClickDelete(id)}
              >
                삭제
              </Button>
              <Button
                color="primary"
                size="large"
                onClick={() => onClickModify(id)}
              >
                수정
              </Button>
            </Stack>
          </Stack>
        </Box>
        <Box
          sx={{
            flex: 9,
            background: 'gary',
            position: 'relative',
          }}
        >
          <Box
            sx={{
              position: 'absolute',
              width: '100%',
              height: '100%',
              top: 0,
              left: 0,
              px: 1,
              py: 2,
            }}
          >
            {backgroundImage ? (
              <img
                width="100%"
                height="100%"
                style={{ objectFit: 'cover', borderRadius: '4px' }}
                src={backgroundImage}
                alt="배경이미지"
              />
            ) : (
              <Skeleton variant="rounded" width="100%" height="100%" />
            )}
          </Box>
          {type === 'default' &&
            (avatar ? (
              <Avatar
                src={avatar}
                sx={{
                  width: 40,
                  height: 40,
                  position: 'absolute',
                  bottom: 0,
                  left: '50%',
                  transform: 'translate(-50%, 0%)',
                }}
              />
            ) : (
              <Skeleton
                variant="circular"
                sx={{
                  width: 40,
                  height: 40,
                  position: 'absolute',
                  bottom: 0,
                  left: '50%',
                  transform: 'translate(-50%, 0%)',
                }}
              />
            ))}
        </Box>
        <Box sx={{ flex: 5, p: 1, minHeight: 0 }}>
          <Stack
            alignItems="center"
            gap={1}
            sx={{ height: '100%', minHeight: 0 }}
          >
            {type === 'default' &&
              (title ? (
                <Typography variant="h5">{title}</Typography>
              ) : (
                <Skeleton variant="rounded" width="120px" height="32px" />
              ))}

            <Typography variant="caption">{caption}</Typography>
            <Box
              sx={{
                flex: 1,
                width: '100%',
                overflowY: 'auto',
              }}
            >
              {type === 'default' ? (
                description ? (
                  <Typography align="center" sx={{ whiteSpace: 'pre-wrap' }}>
                    {description}
                  </Typography>
                ) : (
                  <Skeleton variant="rounded" width="100%" height="100%" />
                )
              ) : description ? (
                <Typography variant="h5" textAlign="center">
                  {description}
                </Typography>
              ) : (
                <Skeleton variant="rounded" width="100%" height="32px" />
              )}
            </Box>
          </Stack>
        </Box>
      </Stack>
    </Card>
  )
}
