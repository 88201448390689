import { Alert, Box, Snackbar } from '@mui/material'
import { createContext, useCallback, useContext, useState } from 'react'

const ToastContext = createContext()

export function ToastContextProvider({ children }) {
  const [toasts, setToasts] = useState([])

  const addToast = useCallback(
    function (toast) {
      setToasts(toasts => [...toasts, toast])
      setTimeout(() => setToasts(toasts => toasts.slice(1)), 3000)
    },
    [setToasts]
  )

  return (
    <ToastContext.Provider value={{ addToast }}>
      {children}

      <Snackbar open={true} autoHideDuration={3000}>
        <Box>
          {toasts.map((toast, index) => (
            <Alert
              key={index}
              severity={toast?.severity || 'success'}
              sx={{ width: '100%' }}
            >
              {toast?.message}
            </Alert>
          ))}
        </Box>
      </Snackbar>
    </ToastContext.Provider>
  )
}

export function useToastContext() {
  return useContext(ToastContext)
}
