import client from './client'

export const getSbt = () => client.get('/admins-customers/nabba/sbt')

export const updateSbtStatus = (_id, isShow) =>
  client.put('/admins-customers/nabba/sbt/active', {
    _id,
    isShow,
  })

export const getSubbadges = badge_id =>
  client.get(`/admins-customers/nabba/badges/${badge_id}/sub-badges`)

export const updateSubBadgeStatus = (_id, isShow) =>
  client.put('/admins-customers/nabba/sub-badges/updateBadgeSubItemShowState', {
    _id,
    isShow,
  })

export const getRequestBages = badgedId =>
  client.get(`/admins-customers/nabba/requestBadges?badgeId=${badgedId}`)

export const updateRequestStatus = (requestBadgeId, isAdminShow) =>
  client.put('/admins-customers/nabba/requestBadges/updateAdminShowState', {
    requestBadgeId,
    isAdminShow,
  })

/**
 * 요청된 뱃지 거절
 */
export const rejectRequest = (requestBadgeId, description) =>
  client.put('/admins-customers/nabba/requestBadges/reject', {
    requestBadgeId,
    description,
  })

/**
 * 요청 뱃지 부여
 */
export const putNabbaRequestBadges = ({ id, subItem, badgeId, userId }) =>
  client.put('/admins-customers/nabba/requestBadges', {
    _id: id,
    subItem_id: subItem,
    badge_id: badgeId,
    user_id: userId,
  })
