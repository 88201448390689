import { createTheme } from '@mui/material/styles'
import componentStyleOverrides from './componentStyleOverrides'

const theme = customization => {
  const themeOptions = {
    palette: {
      mode: 'dark',
    },
  }
  const themes = createTheme(themeOptions)
  themes.components = componentStyleOverrides(themes)
  return themes
}

export default theme
