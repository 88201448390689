import React, { useEffect, useState } from 'react'
import { Navigate } from 'react-router-dom'
import { loginCheck } from '../../api/auth'
import { logout } from '../../service/auth'
import * as Cookie from '../../lib/cookie'
export default function AuthGuard({ children }) {
  const [auth, setAuth] = useState(true)

  const loadUser = async () => {
    const accessToken = Cookie.getCookie('NABBA_ADMIN_ACCESS_TOKEN')
    const refreshToken = Cookie.getCookie('NABBA_ADMIN_REFLESH_TOKEN')

    if (!accessToken || !refreshToken) {
      setAuth(false)
    }

    const {
      data: { roles },
    } = await loginCheck()

    const isAdmin = roles.includes('nabba_admin')

    if (!isAdmin) {
      logout()
    }
    setAuth(isAdmin)
  }

  useEffect(() => {
    loadUser()
  }, [])

  if (auth) {
    return children
  } else {
    return <Navigate to="/login" replace />
  }
}
