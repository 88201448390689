import { Box, Button, Modal, TextField, Typography } from '@mui/material'
import { Stack } from '@mui/system'
import React from 'react'
import UploadArea from '../../molecules/UploadArea/UploadArea'
import BannerItem from '../BannerItem/BannerItem'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import 'dayjs/locale/ko'
import { uploadBannerImage } from '../../../api/banner'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%',
  maxWidth: 789,
  bgcolor: 'background.paper',
  color: 'text.primary',
  boxShadow: 24,
  p: 4,
}

export default function AddBannerModal({
  title,
  open,
  handleClose,
  handleSubmit,
  isModify,
  onChange,
  item,
}) {
  const handleUploadImage = async (id, file) => {
    let fd = new FormData()
    fd.append('file', file)
    const {
      data: {
        data: { url },
      },
    } = await uploadBannerImage(fd)
    onChange(id, url)
  }

  const handleChangeDate = newDate => {
    onChange('closingDate', new Date(newDate).toISOString())
  }

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={style}>
        <Stack gap={2}>
          <Typography variant="h5">{title || '타이틀 영역'}</Typography>
          <Stack direction={'row'}>
            <Stack flex="1" gap={3}>
              <UploadArea
                id="imageUrlPc"
                label="PC 배너 등록"
                width={2110}
                height={1140}
                placeholder="파일 선택"
                helperText="(2110px *1140px)"
                onChangeFile={handleUploadImage}
                url={item.imageUrlPc}
              />
              <UploadArea
                id="imageUrlMobile"
                label="모바일 배너 등록"
                width={1170}
                height={690}
                placeholder="파일 선택"
                helperText="(1170px *690px)"
                onChangeFile={handleUploadImage}
                url={item.imageUrlMobile}
              />
            </Stack>
            <Stack flex="1" px={5} gap={3}>
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                adapterLocale="ko"
              >
                <DatePicker
                  label="배너 종료일자 선택"
                  value={item.closingDate}
                  onChange={handleChangeDate}
                  renderInput={params => <TextField {...params} />}
                />
              </LocalizationProvider>
              <TextField
                label="배너 주소 입력"
                variant="outlined"
                helperText="링크 이동 주소를 입력하세요."
                multiline
                value={item.linkUrl}
                onChange={e => onChange('linkUrl', e.target.value)}
              />
              <BannerItem
                closingDate={item.closingDate}
                pcImage={item.imageUrlPc}
                isActive={item.status}
                index={item.sortNum + 1}
              />
            </Stack>
          </Stack>
          <Stack direction={'row'} justifyContent="flex-end" gap={2}>
            <Button variant="outlined" color="error" onClick={handleClose}>
              취소
            </Button>
            <Button variant="outlined" onClick={() => handleSubmit(isModify)}>
              {isModify ? '수정' : '등록'}
            </Button>
          </Stack>
        </Stack>
      </Box>
    </Modal>
  )
}
