import { loginCheck } from '../api/auth'
import * as Cookie from '../lib/cookie'
import qs from 'qs'

export function signInWithGoogle() {
  const FRONT_URL = process.env.REACT_APP_FRONT_URL || 'http://localhost:3000'
  const queryStr = qs.stringify({
    client_id: `${process.env.REACT_APP_GOOGLE_OIDC_CLIENT_ID}`,
    redirect_uri: `${process.env.REACT_APP_SERVER_API_URL}/admins-customers/nabba/sign/admin`,
    response_type: 'code',
    scope: 'openid profile email',
    prompt: 'select_account',
    state: JSON.stringify({
      callback_uri: `${FRONT_URL}/login`,
    }),
  })
  const loginUrl = `${process.env.REACT_APP_GOOGLE_OIDC_AUTH_URL}?${queryStr}`

  window.location.href = loginUrl
}

export async function afterRedirectByGoogle(location) {
  try {
    const { accessToken, refreshToken } = qs.parse(location.search, {
      ignoreQueryPrefix: true,
    })
    if (!accessToken || !refreshToken) return { isAfter: false }
    Cookie.setCookie('NABBA_ADMIN_ACCESS_TOKEN', accessToken, {})
    Cookie.setCookie('NABBA_ADMIN_REFLESH_TOKEN', refreshToken, {})
    const {
      data: { roles },
    } = await loginCheck()
    const isAdmin = roles.includes('nabba_admin')
    return { isAdmin, isAfter: true }
  } catch (e) {
    console.error(e)
  }
}

export function logout(callback) {
  Cookie.removeCookie('NABBA_ADMIN_ACCESS_TOKEN')
  Cookie.removeCookie('NABBA_ADMIN_REFLESH_TOKEN')
  if (callback) callback()
}
