import { createContext, useContext, useEffect, useState } from 'react'
import { getMyPageInfo } from '../api/auth'
import { logout as serviceLogout } from '../service/auth'

const AuthContext = createContext()

export function AuthContextProvider({ children }) {
  const [user, setUser] = useState()

  useEffect(() => {
    async function fetchData() {
      const {
        data: { data },
      } = await getMyPageInfo()
      setUser(data)
    }

    fetchData()
  }, [])

  const logout = callback => {
    setUser(null)
    serviceLogout(callback)
  }
  return (
    <AuthContext.Provider value={{ user, logout }}>
      {children}
    </AuthContext.Provider>
  )
}

export function useAuthContext() {
  return useContext(AuthContext)
}
