import React from 'react'
import { Button, Modal, Stack, Typography } from '@mui/material'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  color: 'text.primary',
  boxShadow: 24,
  p: 4,
}

export default function ModalFrame({
  title = '타이틀 영역',
  open,
  handleClose,
  handleConfirm,
  confirmLabel,
  cancelLabel,
  children,
}) {
  return (
    <Modal open={open} onClose={handleClose}>
      <Stack sx={style}>
        <Typography variant="h5">{title}</Typography>
        {children}
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="flex-end"
          gap={2}
        >
          {cancelLabel && (
            <Button variant="outlined" color="error" onClick={handleClose}>
              {cancelLabel}
            </Button>
          )}
          {confirmLabel && (
            <Button variant="outlined" onClick={handleConfirm}>
              {confirmLabel}
            </Button>
          )}
        </Stack>
      </Stack>
    </Modal>
  )
}
