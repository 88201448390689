import { Stack, Link, TableCell, TableRow, Pagination } from '@mui/material'
import { useState, useEffect, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import Table from '../../../components/template/Container/Table/Table'
import { headers, categoryItems } from './mock'
import SnsLogo from '../../../components/atoms/SnsLogo/SnsLogo'
import routerRegex from '../../../routes/routerRegex'

// API
import { getUsers as getUsersAPI } from '../../../api/user'

export default function List() {
  const navigate = useNavigate()

  /**
   * Table Header
   */
  const TableHeader = headers

  /**
   * 기본 select options
   */
  const [category] = useState(categoryItems)

  /**
   * 선택된 options
   */
  const [selectedCategory, setSelectedCategory] = useState('name')

  const [keyword, setKeyword] = useState('')
  const [searchKeyword, setSearchKeyword] = useState('')

  /**
   * 유저 조회 데이터 - 테이블 데이터
   */
  const [userList, setUserList] = useState({
    lastPage: 1,
    count: 0,
    paginationData: [],
  })

  const handleKeyword = event => {
    const {
      target: { value },
    } = event
    setKeyword(value)
  }

  const INITIALIZE_IN_USEEFFECT = useCallback(async () => {
    if (searchKeyword !== '') return []
    try {
      const {
        data: [result],
      } = await getUsersAPI({
        keyword: searchKeyword,
        roles: [
          'nabba',
          'nabba_admin',
          'nabba_super_admin',
          'nabba_withdrawal',
        ].join(','),
        searchUsersType: selectedCategory,
        limit: 10,
        page: 1,
      })

      return result
    } catch (err) {
      console.error(err)
    }
  }, [searchKeyword, selectedCategory])

  useEffect(() => {
    ;(async () => {
      try {
        const result = await INITIALIZE_IN_USEEFFECT()
        setUserList(result)
      } catch (err) {
        console.error(err)
      }
    })()
  }, [INITIALIZE_IN_USEEFFECT])

  const handleSubmit = async () => {
    setSearchKeyword(keyword)
    // TODO: 검색 API 연결
    try {
      const {
        data: [result],
      } = await getUsersAPI({
        keyword: keyword,
        roles: [
          'nabba',
          'nabba_admin',
          'nabba_super_admin',
          'nabba_withdrawal',
        ].join(','),
        searchUsersType: selectedCategory,
        limit: 10,
        page: 1,
      })

      setUserList(result)
    } catch (err) {
      console.error(err)
    }
  }

  const loadMore = useCallback(
    async page => {
      try {
        const {
          data: [result],
        } = await getUsersAPI({
          keyword: searchKeyword,
          roles: [
            'nabba',
            'nabba_admin',
            'nabba_super_admin',
            'nabba_withdrawal',
          ].join(','),
          searchUsersType: selectedCategory,
          limit: 10,
          page: page,
        })
        setUserList(result)
      } catch (err) {
        console.error(err)
      }
    },
    [setUserList, selectedCategory, searchKeyword]
  )

  /**
   * Table Row Click Event
   */
  const onClickTableRow = useCallback(
    userId => {
      navigate(`${routerRegex.user.detail.path.replace(/:userId/g, userId)}`)
    },
    [navigate]
  )

  /**
   * Table Header Render
   */
  const renderTableHeader = () => {
    return (
      <TableRow>
        {headers.map(header => (
          <TableCell
            align="center"
            key={header.dataKey}
            sx={{ backgroundColor: 'background.paper' }}
          >
            {header.label}
          </TableCell>
        ))}
      </TableRow>
    )
  }

  const renderTableItem = useCallback(
    (_, data) => {
      return (
        <>
          {data &&
            TableHeader?.map(header => {
              return (
                <TableCell
                  key={header?.dataKey}
                  align="center"
                  onClick={() => onClickTableRow(data['user_id'])}
                  sx={{
                    height: '80px',
                  }}
                >
                  {header?.dataKey === 'snsType' && (
                    <SnsLogo service={data[header?.dataKey]} />
                  )}

                  {(header?.dataKey === 'name' ||
                    header?.dataKey === 'email') &&
                    data[header?.dataKey]}
                  {(header?.dataKey === 'cccv' ||
                    header?.dataKey === 'cccv_nft') && (
                    <Link
                      href={
                        header?.dataKey === 'cccv'
                          ? `${process.env.REACT_APP_CCCV_URL}/${data['pageId']}`
                          : `${
                              process.env.REACT_APP_CCCV_URL
                            }/${`nft/user/${data['pageId']}`}`
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={evt => evt.stopPropagation()}
                    >
                      {header?.dataKey === 'cccv'
                        ? data['pageId']
                        : `nft/${data['pageId']}`}
                    </Link>
                  )}
                </TableCell>
              )
            })}
        </>
      )
    },
    [onClickTableRow, TableHeader]
  )

  return (
    <>
      <Table
        data={userList?.paginationData}
        headerComponent={renderTableHeader}
        itemContent={renderTableItem}
        fixedItemHeight={60}
        searchbarProps={{
          hasSearchbarFilter: true,
          menus: category,
          selectedCategory: [selectedCategory, setSelectedCategory],
          keyword: keyword,
          onChangeKeyword: handleKeyword,
          onSubmit: handleSubmit,
        }}
      />
      <Stack
        spacing={2}
        sx={{
          pb: 5,
        }}
      >
        <Pagination
          count={userList?.lastPage}
          variant="outlined"
          shape="rounded"
          sx={{
            '& .MuiPagination-ul': {
              justifyContent: 'center',
            },
          }}
          onChange={(e, page) => {
            loadMore(page)
          }}
        />
      </Stack>
    </>
  )
}
