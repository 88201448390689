import client from './client'

export const getBanners = () => client.get('/admins-customers/nabba/banner')

export const updateBannersSort = banners =>
  client.patch(`/admins-customers/nabba/banner/sort`, banners)

export const updateBannerStatus = (bannerId, status) =>
  client.patch('/admins-customers/nabba/banner/status', {
    bannerId,
    status,
  })

export const deleteBanner = bannerId =>
  client.delete(`/admins-customers/nabba/banner/${bannerId}`)

export const uploadBannerImage = fd =>
  client.post('/admins-customers/nabba/banner/images', fd, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })

export const addBanner = banner =>
  client.post('/admins-customers/nabba/banner', banner)

export const editBanner = banner =>
  client.put('/admins-customers/nabba/banner', banner)

export const getBannerSortOrder = () =>
  client.get('/admins-customers/nabba/banner/random-option')

export const updateBannerSortOrder = isRandom =>
  client.patch('/admins-customers/nabba/banner/random-option', {
    groupName: 'Nabba',
    isRandom,
  })
