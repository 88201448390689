import routerRegex from '../../../routes/routerRegex'

const getSidebarConfig = [
  {
    text: '유저관리',
    iconName: 'manage_accounts_filled',
    pathRegex: `${routerRegex.user.path}/*`,
    nestedList: [
      {
        text: '전체 유저 정보',
        path: routerRegex.user.list.path,
        pathRegex: `${routerRegex.user.list.path}/*`,
      },
      {
        text: '이달의 선수',
        path: routerRegex.user.players.path,
      },
      {
        text: '역대 PRO',
        path: routerRegex.user.pro.path,
      },
    ],
  },
  {
    text: 'NFT 관리',
    iconName: 'badge_filled',
    pathRegex: `${routerRegex.nft.path}/*`,
    // TODO: 수상인증서 주소 변경하기 nabba 페이지로
    nestedList: [
      {
        text: 'SBT (배지)',
        path: routerRegex.nft.sbt.path,
      },
      {
        text: 'NFT (수상인증서)',
        path: `https://nabbanft.co.kr/awards`,
      },
      {
        text: '대회 히스토리',
        path: routerRegex.nft.competition.path,
      },
    ],
  },
  {
    text: '배너관리',
    iconName: 'view_carousel-filled',
    pathRegex: `${routerRegex.banner.path}/*`,
    path: routerRegex.banner.path,
  },
]

export default getSidebarConfig
