import { Avatar, Button, Typography, Skeleton } from '@mui/material'
import { Stack } from '@mui/system'
import React from 'react'

export default function ActionableAvatar({ name, buttonLabel, action, src }) {
  return (
    <Stack direction="row" alignItems="center" gap={1}>
      {src ? (
        <Avatar src={src} sx={{ width: 40, height: 40 }}></Avatar>
      ) : (
        <Skeleton variant="circular" sx={{ width: 40, height: 40 }} />
      )}
      <Stack alignItems="flex-start" justifyContent="flex-start" gap={1}>
        <Typography variant="body2" sx={{ color: 'text.primary' }}>
          {name ? name : <Skeleton sx={{ width: 64 }} />}
        </Typography>
        <Button variant="outlined" size="medium" onClick={action}>
          {buttonLabel || '버튼'}
        </Button>
      </Stack>
    </Stack>
  )
}
