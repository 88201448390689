import { Link, useLocation } from 'react-router-dom'

// MUI
import { Tabs, Tab, Box } from '@mui/material'

export default function LinkTabs({ tabs }) {
  const { pathname } = useLocation()

  return (
    <Box sx={{ width: '100%' }}>
      <Tabs value={pathname}>
        {tabs.map(tab => (
          <Tab
            key={tab.key}
            value={tab.path}
            label={tab.label}
            to={tab.path}
            component={Link}
          />
        ))}
      </Tabs>
    </Box>
  )
}
