import client from './client'

export const getCompetitions = () =>
  client.get('/admins-customers/nabba/competition')

export const updateCompetition = competition =>
  client.put('/admins-customers/nabba/competition', competition)

export const updateCompetitionOrder = order =>
  client.patch('/admins-customers/nabba/competition/sort-order', { order })

export const delateCompetition = groupId =>
  client.delete(`/admins-customers/nabba/competition/${groupId}`)
